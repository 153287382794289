import React from "react";
import { Space, Spin } from 'antd';

export const PageLoader = () => {
    const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";

    return (
        //<div className="loader">
        //    <img src={loadingImg} alt="Loading..." />
        //</div>
        <Space className="nimbly-loading-icon">
        <Spin size="large">
            <div className="content" />
            </Spin>
        </Space>
    );
};