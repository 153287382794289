import React, { Component } from 'react';
//import './style/StyleSheet.css';
import { Col, Row } from 'antd';
import { Space, Table, Tag, Card, Pagination, Button, Modal } from 'antd';
import { RollbackOutlined, InfoCircleOutlined } from '@ant-design/icons';
import MapModal from './mapModal';



const columnsPole = [
    {
        title: 'Pole Information',
        dataIndex: 'name',
        colSpan: 2,
        key: 'name',
        render: (text) => <a className="nimbly-report-columnName">{text}</a>,
    },
    {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        colSpan: 0,
    }
];

const columnsAttacher = [
    {
        title: 'Attacher Information',
        dataIndex: 'name',
        colSpan: 2,
        key: 'name',
        render: (text) => <a className="nimbly-report-columnName">{text}</a>,
    },
    {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        colSpan: 0,
    }
];

class ReportView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isauthenticated: false,
            currentPage: 1,
            originalData: null,
            locationTableData: null,
            poleInfoTableData: null,
            attacherData: null,
            totalReports: 0,
            imageList: [],
            imageCurrentShow: [],
            currentPolePage: 1,
            currentAttacher: null,
            currentAttacherPage: 1,
            isModalOpen: false,
            currentCoord: null
        };
    }

    componentDidMount() {
        //console.log("Report Data Received: ", this.props.showPoleInfoList);
        this.populateReportData(this.props.showPoleInfoList);
    }

    handleModalOpen = (coords) => {
        this.setState({
            isModalOpen: true,
            currentCoord: coords
        });
    }

    handleModalClose = () => {
        this.setState({
            isModalOpen: false,
            currentCoord : null
        });
    }

    handleFormatKey = (keyName) => {
        if (keyName === "address") {
            return "Address"
        }
        else if (keyName === "coordinates") {
            return "Coordinates"
        }
        else if (keyName === "owner") {
            return "Owner"
        }
        else if (keyName === "states") {
            return "State"
        }
        else if (keyName === "area") {
            return "Area"
        }
        else if (keyName === "poleNumber") {
            return "Pole Number"
        }
        else if (keyName === "duke_Pole_Number") {
            return "Duke Pole Number"
        }
        else if (keyName === "poleStatus") {
            return "Pole Status"
        }
        else if (keyName === "pullPoleType") {
            return "Pull Pole Type"
        }
        else if (keyName === "accessible") {
            return "Accessible"
        }
        else if (keyName === "restoration") {
            return "Restoration"
        }
        else if (keyName === "restored") {
            return "Restored"
        }
        else if (keyName === "restorationDate") {
            return "Restoration Date"
        }
        else if (keyName === "njuns") {
            return "NJUNS Number"
        }
        else if (keyName === "removalDate") {
            return "Removal Date"
        }
        else if (keyName === "poleRemover") {
            return "Pole Remover"
        }
        else if (keyName === "auditDate") {
            return "Audit Date"
        }
        else if (keyName === "auditor") {
            return "Auditor"
        }
        else if (keyName === "comments") {
            return "Notes"
        }
        else if (keyName === "attcompany") {
            return "Attachment Company"
        }
        else if (keyName === "transtype") {
            return "Transfer Type"
        }
        else if (keyName === "transqty") {
            return "Transfer QTY"
        }
        else if (keyName === "auditor_Notes") {
            return "Auditor Notes"
        }
        else if (keyName === "crew_Notes") {
            return "Crew Notes"
        }

        else if (keyName === "transdate") {
            return "Transfer Date"
        }
        else if (keyName === "transcrew") {
            return "Transfer Crew"
        }
        else if (keyName === "audit_Date") {
            return "Audit Date"
        }
        else if (keyName === "attachment_Status") {
            return "Attachment Status"
        }
        else if (keyName === "spansNum") {
            return "SPANS Number"
        }
        else if (keyName === "cincinnatiBell_DeliveryArea") {
            return "Cincinnati Bell Delivery Area"
        }
        else if (keyName === "auditor_Comments") {
            return "Auditor Comments"
        }
        else if (keyName === "atT_POSITION") {
            return "Attach Position"
        }
        else if (keyName === "attachment_Status") {
            return "Attachment Status"
        }
        else if (keyName === "pole_Topped") {
            return "Pole Topped"
        }
        else if (keyName === "topping_Crew") {
            return "Topping Crew"
        }
        else if (keyName === "topping_Date") {
            return "Topping Date"
        }
        //else if (keyName === "invoiceDate") {
        //    return "Invoice Date"
        //}
        //else if (keyName === "district") {
        //    return "District"
        //}
        //else if (keyName === "globalID") {
        //    return "GlobalID"
        //}
        else {
            return keyName
        }
    }

    handleDataConversion = (currentPoleIndex) => {
        //console.log("1");
        var currentPole = this.props.showPoleInfoList[currentPoleIndex - 1];
        const convertedDataLocation = [];
        let convertedDataPole = [];
        var imageList = [];
        var convertedDataAttacher = [];
        var obj = {};
        const listofLocationInfo = ["address", "coordinates", "owner", "states", "area"];
        if (currentPole !== null && typeof currentPole !== 'undefined') {
            for (const [key, value] of Object.entries(currentPole).filter(([key, _]) => key !== "lat" && key !== "long")) {
                if (key !== "imagePath" && key !== "attachers" && key !== "globalID" && key !== "invoiceDate" && key !== "district" && key !== "spansNum" && key !== "key" && key !== "attachment_Status") {
                    obj = {};
                    //console.log(key, value)
                    obj["name"] = this.handleFormatKey(key);
                    obj["content"] = value;
                    obj["key"] = this.handleFormatKey(key);
                    if (listofLocationInfo.includes(key)) {
                        convertedDataLocation.push(obj);
                    } else {
                        convertedDataPole.push(obj);
                    }
                }
                else if (key === "imagePath") {
                    if (value != null) {
                        imageList = imageList.concat(value.split(","));

                    }
                }
            }

            convertedDataPole.forEach((obj, i) => {
                if (obj.name == "Pole Topped") {
                    if (obj.content == null) {
                        convertedDataPole.splice(convertedDataPole.findIndex(item => item.name === "Topping Crew"), 1)
                        convertedDataPole.splice(convertedDataPole.findIndex(item => item.name === "Topping Date"), 1)
                        convertedDataPole.splice(convertedDataPole.findIndex(item => item.name === "Pole Topped"), 1)
                    }
                    else {
                        if (obj.content != "Yes") {
                            convertedDataPole.splice(convertedDataPole.findIndex(item => item.name === "Topping Crew"), 1)
                            convertedDataPole.splice(convertedDataPole.findIndex(item => item.name === "Topping Date"), 1)
                        }
                    }
                }
            });
        }
        var restorationIndex = convertedDataPole.findIndex((x) => x.name === "Restoration");
        //console.log(restorationIndex);
        var myArray = convertedDataPole.filter(function (obj) {
            return obj.name !== "Restored";
        });
        //console.log(myArray);

        var restored = convertedDataPole.filter(function (obj) {
            return obj.name === "Restored";
        });
        //console.log(restored);

        myArray.splice(restorationIndex, 0, restored[0]);
        convertedDataPole = myArray;
        //console.log(convertedDataPole);

        //console.log(restorationIndex, myArray, restored);
        var startIndex = 0;
        var endIndex = imageList.length > 4 ? 4 : imageList.length;
        var slicedImage = imageList.slice(startIndex, endIndex);

        var Attachers = currentPole.attachers;
        if (Attachers != null && Attachers.length > 0) {
            for (var i in Attachers) {
                var attacher = Attachers[i];
                var attacherSingle = [];
                for (const [key, data] of Object.entries(attacher).filter(([key, _]) => key !== "lat" && key !== "long")) {
                    if (key !== "reL_GlobalID" && key !== "key" && key !== "Pole Number" && key !== "poleinfo") {
                        obj = {};
                        obj["name"] = this.handleFormatKey(key);
                        obj["content"] = data;
                        obj["key"] = this.handleFormatKey(key);
                        attacherSingle.push(obj);
                    }
                }
                convertedDataAttacher.push(attacherSingle);
            }
        }
        //console.log("3");
        //console.log(convertedDataLocation, convertedDataPole, convertedDataAttacher);
        this.setState({
            locationTableData: convertedDataLocation,
            poleInfoTableData: convertedDataPole,
            imageList: imageList,
            imageCurrentShow: slicedImage,
            attacherData: convertedDataAttacher,
            currentAttacher: convertedDataAttacher.length > 0 ? convertedDataAttacher[0] : null
        });
    };


    handleAttacherDataConversion = (currentPoleIndex) => {
        var Attachers = this.props.showPoleInfoList[currentPoleIndex].attachers;
        if (Attachers != null && Attachers.length > 0) {
            var convertedDataAttacher = [];
            var obj = {};
            for (var i in Attachers) {
                var attacher = Attachers[i];
                var attacherSingle = [];
                for (const [key, data] of Object.entries(attacher).filter(([key, _]) => key !== "lat" && key !== "long")) {
                    if (key !== "reL_GlobalID") {
                        obj = {};
                        obj["name"] = this.handleFormatKey(key);
                        obj["content"] = data;
                        obj["key"] = this.handleFormatKey(key);
                        attacherSingle.push(obj);
                    }
                }
                convertedDataAttacher.push(attacherSingle);
            }
        }
        this.setState({
            attacherData: convertedDataAttacher,
        });

    }
    handlePageChange = (pageNumber) => {
        this.handleDataConversion(pageNumber);
        this.setState({
            currentPage: 1,
            currentPolePage: pageNumber
        });
    }

    handleImagePageChange = (pageNumber) => {
        var startIndex = 0;
        var endIndex = this.state.imageList.length > 4 ? 4 : this.state.imageList.length;
        if (pageNumber !== 1) {
            
            startIndex = pageNumber * 4 - 4;
            endIndex = pageNumber * 4;
        }
        var slicedImage = this.state.imageList.slice(startIndex, endIndex);
        this.setState({
            imageCurrentShow: slicedImage,
            currentPage: pageNumber,
        })
    }

    handleAttacherPageChange = (pageNumber) => {
        this.setState({
            currentAttacher: this.state.attacherData == null ? null : this.state.attacherData[pageNumber - 1],
            currentAttacherPage: pageNumber
        })
    }


    render() {

        const columnsLocation = [
            {
                title: 'Location Details',
                dataIndex: 'name',
                colSpan: 2,
                key: 'name',
                render: (text) => <a className="nimbly-report-columnName">{text}</a>,
            },
            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
                colSpan: 0,
                render: (_, record) => (
                    record.name === "Coordinates" ?
                        <Space>
                            <a onClick={() => this.handleModalOpen(record.content)}>{record.content}</a>
                        </Space>
                        : record.content
                )
            }
        ];

        return (
            <div>
                <Row>
                    <Col span={3}>
                        <Row className={this.props.dataSource === "map" ? "nimbly-report-mapview" : "nimbly-report-view" }>
                                <Row gutter={[8, 8]} className="nimbly-image-container">
                                {this.state.imageCurrentShow == null || this.state.imageCurrentShow.length === 0 ?
                                    <Col span={24} >
                                    <Card className="nimbly-message-container">
                                            <div className="nimbly-noimage-message"><InfoCircleOutlined /><span>No Images</span></div>
                                    </Card>
                                </Col> :
                                    this.state.imageCurrentShow.map((item) => (
                                        <Col span={12} >
                                            {this.props.dataSource === "map" ? <Card
                                                style={{
                                                    //width: 130,
                                                    //height: 120
                                                }}
                                                className="nimbly-image-card"
                                            >
                                                <a rel="noreferrer" href={item} target="_blank">

                                                    <img
                                                        alt=""
                                                        src={item} style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: '5px'
                                                        }}
                                                    />
                                                </a>

                                            </Card> : 
                                        <Card
                                        style={{
                                            //width: 210,
                                            //height: 160
                                                }}
                                                className="nimbly-image-card"
                                            >
                                                <a rel = "noreferrer" href={item} target="_blank">

                                                <img
                                                    alt=""
                                                    src={item} style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: '5px'
                                                    }}
                                                    />
                                                </a>

                                                </Card>
                                            }
                                    </Col>
                                    ))}
                            </Row>
                            {this.state.imageCurrentShow == null || this.state.imageCurrentShow.length === 0 ? null :
                                <div className="nimbly-report-pagination-wrapper">
                                    <Pagination
                                        total={this.state.imageList.length}
                                        onChange={(pageNumber) => this.handleImagePageChange(pageNumber)}
                                        size="small"
                                        pageSize={4}
                                        current={this.state.currentPage}
                                        showSizeChanger={false}
                                /> 
                                </div>}

                        </Row>
                    </Col>
                    <Col span={3}>
                        <Table className="nimbly-report-information" columns={columnsPole} dataSource={this.state.poleInfoTableData == null ? null : this.state.poleInfoTableData} pagination={false} />
                    </Col>
                    {this.state.attacherData == null || this.state.attacherData.length === 0 ? null :
                    <Col span={3}>
                        <Table className="nimbly-report-information" columns={columnsAttacher} dataSource={this.state.currentAttacher == null ? null : this.state.currentAttacher} pagination={false} />
                        {this.state.attacherData == null || this.state.attacherData.length === 0 ? null :
                            <div className="nimbly-report-pagination-wrapper">
                                <Pagination
                                    total={this.state.attacherData.length}
                                    onChange={(pageNumber) => this.handleAttacherPageChange(pageNumber)}
                                    size="small"
                                    pageSize={1}
                                    current={this.state.currentAttacherPage}
                                    showSizeChanger={false}
                                />
                                    </div>
                                }
                        
                    </Col>
                    }
                    <Col>
                        <Table className="nimbly-report-location-tableview" columns={columnsLocation} dataSource={this.state.locationTableData == null ? null : this.state.locationTableData} pagination={false} />
                    </Col>

                </Row>
                {this.props.dataSource === "map" ? null :
                    <div className="nimbly-report-pagination-wrapper">
                        <Pagination
                            total={this.state.totalReports}
                            onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                            size="large"
                            pageSize={1}
                            showSizeChanger={false}
                            current={this.state.currentPolePage}
                        />
                    </div>
                }
                <Modal
                    open={this.state.isModalOpen}
                    footer={[
                    <Button key="back" onClick={() => this.handleModalClose()}>
                        Close Map
                    </Button>,
                    ]}
                    closable={false}

                >
                    <MapModal coords={this.state.currentCoord} />
                </Modal>
            </div>
        );
    }

    async populateReportData(formData) {
        //const response = await fetch('report', {
        //    headers: {
        //        'Accept': 'application/json',
        //        'Content-Type': 'application/json'
        //    },
        //    credentials: 'include',
        //    method: 'post',
        //    body: JSON.stringify(
        //        {
                    //AuditCompletedDate: formData.AuditCompletedDate == null ? "1999/01/01" : formData.AuditCompletedDate,
                    //AuditStartDate: formData.AuditStartDate == null ? "9999/01/01" : formData.AuditStartDate,
                    //City: formData.City == null ? "p.DE_Municipality" : formData.City,
                    //NjunNumber: formData.NjunNumber == null ? "p.NJUNSNUM" : formData.NjunNumber,
                    //PoleNumber: formData.PoleNumber == null ? "p.POLENUM" : formData.PoleNumber,
                    //PoleStatus: formData.PoleStatus == null ? "p.POLESTATUS" : formData.PoleStatus,
                    //PoleType: formData.PoleType == null ? "p.PULLTYPE" : formData.PoleType,
                    //Ragion: formData.Ragion == null ? "p.DE_State" : formData.Ragion,
                    //RemovalCompletedDate: formData.RemovalCompletedDate == null ? "9999/01/01" : formData.RemovalCompletedDate,
                    //RemovalStartDate: formData.RemovalStartDate == null ? "1999/01/01" : formData.RemovalStartDate,
                    //Restoration: formData.Restoration == null ? "p.RESTORATION" : formData.Restoration,
                    //SpanNumber: formData.SpanNumber == null ? "p.SPANSNUM" : formData.SpanNumber,
                    //auditor: formData.auditor == null ? "p.Audit_User" : formData.auditor,
                    //crew: formData.crew == null ? "p.Removal_Crew" : formData.crew,
        //            AuditCompletedDate: formData.AuditCompletedDate,
        //            AuditStartDate: formData.AuditStartDate,
        //            City: formData.City,
        //            NjunNumber: formData.NjunNumber,
        //            PoleNumber: formData.PoleNumber,
        //            PoleStatus: formData.PoleStatus,
        //            PoleType: formData.PoleType,
        //            Ragion: formData.Ragion,
        //            RemovalCompletedDate: formData.RemovalCompletedDate,
        //            RemovalStartDate: formData.RemovalStartDate,
        //            Restoration: formData.Restoration,
        //            SpanNumber: formData.SpanNumber,
        //            auditor: formData.auditor,
        //            crew: formData.crew,
        //        }
        //    )
        //});
        //const data = await response.json();
        //console.log(data.poleList);

        //var sampleData = this.state.originalData === null ? null : this.state.originalData.filter(function (data) {
        //    return data.imagePath !== null
        //})


        this.setState({
            //originalData: formData,
            loading: false,
            totalReports: this.props.showPoleInfoList != null ? this.props.showPoleInfoList.length : 0,
        });
        this.handleDataConversion(1);
    }
}

export default ReportView;
