import React, { Component } from 'react';
import { GoogleMap, LoadScript, MarkerF, Autocomplete, InfoWindow } from '@react-google-maps/api';
import ReportViewTable from './reportviewtable'
import { Card, Checkbox, Row, Drawer, Button, Spin, Tooltip } from 'antd';
import pin from '../img/pin.png';
import ComplexTransfer from '../img/ComplexTransfer.png';
import IdlePoles from '../img/IdlePoles.png';
import LocationsCompleted from '../img/LocationsCompleted.png';
import LocationsNotAudited from '../img/LocationsNotAudited.png';
import LocationsWaiting from '../img/LocationsWaiting.png';
import SimpleTransfers from '../img/SimpleTransfers.png';
import { DoubleRightOutlined, DoubleLeftOutlined, FullscreenOutlined, FullscreenExitOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ReportView from './reportview'

const mapOptions = {
    fullscreenControl: false,
};


const containerStyle = {
    width: '100%',
    height: '48vh',
    borderRadius: '10px',
    borderColor: '#BFBFBF'
}

const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15,
    color: 'black',
    fontSize: "24px"
}

const containerStyleFullSize = {
    width: '100%',
    height: '81vh',
    borderRadius: '10px',
    borderColor: '#BFBFBF'
}

class MapModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapData: [],
            currentSelectedPole: null,
            currentCenter: {
                lat: 39.7684,
                lng: -86.1581
            },
            showInfoWindow: true,
            checkedMaplegend: null,
            originalData: null,
            showDrawer: true,
            currentPin: "default",
            loading: true,
        };
        this.autocomplete = null

        this.onLoad = this.onLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
        this.handleMarkerOnClick = this.handleMarkerOnClick.bind(this)
        this.handleMapOnClick = this.handleMapOnClick.bind(this)
        this.handlePoleonChange = this.handlePoleonChange.bind(this)

    }

    componentDidMount() {
        //this.populateMapData();
        //console.log(this.props.coords);
        //var coordsList = this.props.coords.split(",");
        //var obj = {};
        //obj["lat"] = Number(coordsList[0].replace(/\s/g, ''));
        //obj["lng"] = Number(coordsList[1].replace(/\s/g, ''));
        //console.log(obj);
        //this.setState({
        //    currentCenter: obj,
        //})
    }

    componentDidUpdate(prevProps) {
        if (this.props.coords !== prevProps.coords) {
            var coordsList = this.props.coords.split(",");
            var obj = {};
            obj["lat"] = Number(coordsList[0].replace(/\s/g, ''));
            obj["lng"] = Number(coordsList[1].replace(/\s/g, ''));
            this.setState({
                currentCenter: obj,
            })
        }

    }
    handlePoleonChange(e, type, pinType) {

        var locationWaitingStatusList = ["Pole not Set", "Electric Power", "Heavy Vegetation", "Pole Needs Topping", "No Access"]
        var updatedMapData;
        if (type === this.state.checkedMaplegend) {

            this.setState({
                mapData: [],
            }, () => {
                this.setState({
                    checkedMaplegend: null,
                    mapData: this.state.originalData,
                    currentPin: "default",
                    currentSelectedPole: null,
                })
            })
        }
        else {
            if (type === "Complex Transfers") {
                updatedMapData = this.state.originalData.filter(function (data) {
                    return data.info.poleStatus === type
                })
            }
            else if (type === "Locations Waiting") {
                updatedMapData = this.state.originalData.filter(function (data) {
                    return data.info.poleStatus === "Pole not Set" || data.info.poleStatus === "Electric Power" || data.info.poleStatus === "Heavy Vegetation" || data.info.poleStatus === "Pole Needs Topping" || data.info.poleStatus === "No Access"
                })
            }
            else if (type === "Locations Completed") {
                updatedMapData = this.state.originalData.filter(function (data) {
                    return data.info.poleStatus === "Pulled"
                })
            }
            else if (type === "Idle Poles") {
                updatedMapData = this.state.originalData.filter(function (data) {
                    return data.info.transferType === "Idle"
                })
            }
            else if (type === "Simple Transfers") {
                updatedMapData = this.state.originalData.filter(function (data) {
                    return data.info.transferType === "Simple Transfer"
                })
            }
            this.setState({
                mapData: [],
            }, () => {
                this.setState({
                    checkedMaplegend: type,
                    mapData: updatedMapData,
                    currentSelectedPole: null,
                    currentPin: pinType

                })
            })
        }

    }

    handleMarkerOnClick(marker, e) {
        this.setState({
            currentSelectedPole: marker,
            showInfoWindow: true
        })
    }

    handleMapOnClick() {
        this.setState({
            currentSelectedPole: null,
        })
    }

    onLoad(autocomplete) {
        this.autocomplete = autocomplete
    }

    onPlaceChanged() {
        if (this.autocomplete !== null) {
            var searchCallback = this.autocomplete.getPlace();
            var searchInput = searchCallback.name.toString();
            var lookupData = this.state.mapData;
            var test = lookupData.filter(function (data) {
                return lookupData !== null ? data.info.poleNumber === searchInput : null
            });
            test = lookupData.filter(function (data) {
                return lookupData !== null ? data.info.coordinates === searchInput : null
            });

            this.setState({
                currentSelectedPole: test.length === 0 ? null : test[0]
            })
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    onMarkerClick = (props, marker) => {

    }

    showDrawer = () => {
        this.setState({
            showDrawer: !this.state.showDrawer
        })
    }
    render() {
        return (
            //<LoadScript
            //    //id="script-loader"
            //    googleMapsApiKey="AIzaSyB1rmwwP3SKl8a47tX4BYPIIwQZsuWLZRQ"
            //    libraries={libraries}
            //    preventGoogleFontsLoading={true}
            //>  
            <GoogleMap
                mapContainerStyle={this.props.fullSizeMap ? containerStyleFullSize : containerStyle}
                center={this.state.currentCenter}
                zoom={8}
                //className="no-close-button-on-info-windows"
                onClick={() => this.handleMapOnClick()}
                options={mapOptions}

            >
                    <MarkerF
                        //key={index}
                    position={this.state.currentCenter}
                        //onClick={(e) => this.handleMarkerOnClick(item, e)}
                        icon={pin}
                    >
                    </MarkerF>
                <></>
            </GoogleMap>
        )
    }

    async populateMapData() {
        //const response = await fetch('report', {
        //    headers: {
        //        'Accept': 'application/json',
        //        'Content-Type': 'application/json'
        //    },
        //    credentials: 'include',
        //    method: 'post',
        //    body: JSON.stringify(
        //        {
        //        }
        //    )
        //});
        //const data = await response.json();

        const markerList = [];
        if (this.props.poleDataProps !== null) {
            for (var index in this.props.poleDataProps.poleList) {
                var pole = this.props.poleDataProps.poleList[index];
                var obj = {};
                var poleInfo = {};
                obj["lat"] = +pole.lat;
                obj["lng"] = +pole.long;
                poleInfo["info"] = this.props.poleDataProps.poleList[index];
                poleInfo["coords"] = obj;
                markerList.push(poleInfo);
            }
        }

        var markerListUpdated = markerList.filter(function (data) {
            return data.info.poleStatus !== "Not Doublewood" && data.info.poleStatus !== "Ready to Go" && data.info.poleStatus !== "Foreign Owened" && data.info.poleStatus !== "Added by Mistake" && data.info.poleStatus !== "Does Not Exist"
        })
        this.setState({
            mapData: markerListUpdated,
            originalData: markerListUpdated,
            loading: false,
        });
    }
}

export default MapModal;
