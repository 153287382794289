import { FileOutlined, PieChartOutlined, UserOutlined, DesktopOutlined, SettingOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Col, Row } from 'antd';
import logoblue from './img/logo-blue.png'; // with import
import delogo from './img/deLogo.png'; // with import
import { useAuth0 } from "@auth0/auth0-react";
import React, { Component } from 'react';
//import './style/StyleSheet.css';
import QueryWizard from './querywizard';
import Dashboard from './dashborad';
import { Avatar, Space } from 'antd';
import Profile from './profile';
import {
    Routes,
    Route,
} from "react-router-dom";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}
const items = [
    getItem('Navigation Three', 'sub4', <SettingOutlined />, [
    getItem('Dashboard', '1', <PieChartOutlined />),
    getItem('Query Wizard', '2', <DesktopOutlined />),
    ])
];

class SiderView extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {
            isauthenticated: false,
            currentPage: "1"
        };
    }

    componentDidMount() {
        //this.populateWeatherData();
    }



    onClick = (e) => {
        this.setState({
            currentPage: e.key
        });
    };


    render() {
        return (
                <Layout>
      <Header className="header nimbly-header">
                            <div
                        style={{
                            height: 32,
                            margin: 6,
                            background: 'white',
                            width: '100%'

                        }}
                    >
                        <Row>
                            <Col span={20}>
                                <img className="" src={logoblue} />
                            </Col>
                            <Col span={4}>
                                <Profile />
                            </Col>
                        </Row>
                    </div>
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{
            background: "white",
          }}
                    >
                        <div
                            style={{
                                height: 60,
                                margin: 6,
                                width: 160,
                                margin : 10
                            }}
                        >
                            <img className="" src={delogo} />

                            </div>
          <Menu
                            mode="inline"
                            defaultSelectedKeys={[this.state.currentPage]}
            style={{
              height: '100%',
              borderRight: 0,
            }}
                            items={items}
                            onClick={(e) => this.onClick(e)}
                        />
                        <Routes>
                            {/*<Route exact path="/login" element={<Login />} />*/}
                            <Route exact path="/dashboard" element={<Dashboard />} />
                            <Route exact path="/recovery-password" element={<QueryWizard />} />
                        </Routes>
        </Sider>
        <Layout
          style={{
            padding: '0 24px 24px',
          }}
        >
          <Content
            style={{
              padding: 24,
              margin: 10,
              minHeight: 280,
              background: "white",
            }}
                        >
                            {this.state.currentPage === "1" ? <Dashboard /> : <QueryWizard />}
          </Content>
                                <Footer
          style={{
            textAlign: 'center',
          }}
        >
         Davey Resource Group �2023 Created by UAM Technical Product Services 
        </Footer>
        </Layout>
      </Layout>
    </Layout>

              
        );
    }

    async populateWeatherData() {
        const response = await fetch('weatherforecast');
        const data = await response.json();
        this.setState({ forecasts: data, loading: false });
    }
}

export default SiderView;
