import React, { Component } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from 'react-router-dom';
//import './style/app.css';
import { Col, Row } from 'antd';
import { Space, Table, Tag, Card, Pagination, Button, Modal } from 'antd';
import { RollbackOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { PageLoader } from "./page-loader";
import { NoAccess } from './noaccess';

const CheckReportAuthentication = (props) => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    return user;
};
const columnsPole = [
    {
        title: 'Pole Information',
        dataIndex: 'name',
        colSpan: 2,
        key: 'name',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        colSpan: 0,
    }
];

export function withRouter(Children) {
    return (props) => {
        //const match = { params: encodeURIComponent(useParams()) };
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class InvoiceReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            poleList: null,
            haspermission: false,
            isloading: true
        };
    }

    componentDidMount() {
        this.populateWeatherData(this.props.match.params);
    }

    handleFormatKey = (keyName) => {
        if (keyName === "address") {
            return "Address"
        }
        else if (keyName === "coordinates") {
            return "Coordinates"
        }
        else if (keyName === "owner") {
            return "Owner"
        }
        else if (keyName === "states") {
            return "State"
        }
        else if (keyName === "area") {
            return "Area"
        }
        else if (keyName === "poleNumber") {
            return "Pole Number"
        }
        else if (keyName === "duke_Pole_Number") {
            return "Duke Pole Number"
        }
        else if (keyName === "poleStatus") {
            return "Pole Status"
        }
        else if (keyName === "pullPoleType") {
            return "Pull Pole Type"
        }
        else if (keyName === "accessible") {
            return "Accessible"
        }
        else if (keyName === "restoration") {
            return "Restoration"
        }
        else if (keyName === "restorationDate") {
            return "Restoration Date"
        }
        else if (keyName === "njuns") {
            return "NJUNS Number"
        }
        else if (keyName === "removalDate") {
            return "Removal Date"
        }
        else if (keyName === "poleRemover") {
            return "Pole Remover"
        }
        else if (keyName === "auditDate") {
            return "Audit Date"
        }
        else if (keyName === "auditor") {
            return "Auditor"
        }
        else if (keyName === "comments") {
            return "Notes"
        }
        else if (keyName === "attcompany") {
            return "Attachment Company"
        }
        else if (keyName === "transtype") {
            return "Transfer Type"
        }
        else if (keyName === "transqty") {
            return "Transfer QTY"
        }
        else if (keyName === "auditor_Notes") {
            return "Auditor Notes"
        }
        else if (keyName === "crew_Notes") {
            return "Crew Notes"
        }
        else if (keyName === "restored") {
            return "Restored"
        }
        else if (keyName === "transdate") {
            return "Transfer Date"
        }
        else if (keyName === "transcrew") {
            return "Transfer Crew"
        }
        else if (keyName === "audit_Date") {
            return "Audit Date"
        }
        else if (keyName === "attachment_Status") {
            return "Attachment Status"
        }
        else if (keyName === "spansNum") {
            return "SPANS Number"
        }
        else if (keyName === "cincinnatiBell_DeliveryArea") {
            return "Cincinnati Bell Delivery Area"
        }
        else if (keyName === "invoiceDate") {
            return "Invoice Date"
        }
        else if (keyName === "district") {
            return "District"
        }
        else if (keyName === "globalID") {
            return "GlobalID"
        }
        else if (keyName === "pole_Topped") {
            return "Pole Topped"
        }
        else if (keyName === "topping_Crew") {
            return "Topping Crew"
        }
        else if (keyName === "topping_Date") {
            return "Topping Date"
        }
        else {
            return keyName
        }
    }

    handleDataConversion = (oripoleList, attCompanyName) => {
        var finallist = [];
        //console.log(attCompanyName);
        if (attCompanyName === "davey" || attCompanyName === "duke-energy") {
            finallist = oripoleList;
        }
        else {
            //if (oripoleList.attCompany !== null && oripoleList.attCompany !== "") {
            //    finallist = oripoleList.filter(function (data) {
            //        var re = data.attachers == null ? false : data.attachers.filter(function (data) {
            //            return (data.attcompany.toLowerCase() === attCompanyName.toString().toLowerCase())
            //        });
            //        var result = re === true || re.length > 0 ? true : false;
            //        return (result)
            //    })
            //}
        }
        //var currentPole = this.props.showPoleInfoList[currentPoleIndex - 1];
        var poleList = [];

        for (var poleindex in oripoleList) {
            var currentPole = oripoleList[poleindex];
            var objPole = {};
            var imageList = [];
            var convertedData = [];
            var convertedDataAttacher = [];
            if (currentPole !== null && typeof currentPole !== 'undefined') {
                var order = 0;
                for (const [key, value] of Object.entries(currentPole).filter(([key, _]) => key !== "lat" && key !== "long")) {
                    if (key !== "imagePath" && key !== "attachers" && key !== "auditor" && key !== "auditDate" && key !== "attachment_Status") {
                        if (order === 0) {
                            var obj = {};
                            obj["name"] = this.handleFormatKey(key);
                            obj["content"] = value;
                            order = 1;                      
                        } else if (order === 1) {
                            obj["name1"] = this.handleFormatKey(key);
                            obj["content1"] = value;
                            order = 0
                        }
                        if (order === 1) {
                            convertedData.push(obj);
                        }
                    }
                    else if (key === "imagePath") {
                        if (value != null) {
                            imageList = imageList.concat(value.split(","));

                        }
                    }

                }
                convertedData.forEach((obj, i) => {
                    if (obj.hasOwnProperty('name')) {
                        if (obj.name == "Pole Topped") {
                            if (obj.content == null) {
                                convertedData.splice(convertedData.findIndex(item => item.name === "Topping Date"), 1)
                                convertedData.splice(convertedData.findIndex(item => item.name === "Pole Topped"), 1)
                            }
                            else {
                                if (obj.content != "Yes") {
                                    delete obj.name1;
                                    delete obj.content1;
                                    convertedData.splice(convertedData.findIndex(item => item.name === "Topping Date"), 1)
                                }
                            }
                        }
                    }
                });

                var Attachers = currentPole.attachers;
                if (Attachers != null && Attachers.length > 0) {
                    if (attCompanyName === "davey" || attCompanyName === "duke-energy") {
                        if (Attachers != null && Attachers.length > 0) {
                            for (var i in Attachers) {
                                var attacher = Attachers[i];
                                var attacherSingle = [];
                                for (const [key, data] of Object.entries(attacher).filter(([key, _]) => key !== "lat" && key !== "long")) {
                                    if (key !== "reL_GlobalID" && key !== "transtype" && key !== "auditor_Comments") {
                                        obj = {};
                                        obj["name"] = this.handleFormatKey(key);
                                        obj["content"] = data;
                                        obj["key"] = this.handleFormatKey(key);
                                        attacherSingle.push(obj);
                                    }
                                }
                                convertedDataAttacher.push(attacherSingle);
                            }
                        }
                    }
                }

                else {
                    if (Attachers != null && Attachers.length > 0) {
                        for (var i in Attachers) {
                            var attacher = Attachers[i];
                            //if (attacher.attcompany.toLowerCase() === attCompanyName.toLowerCase()) {
                            var attacherSingle = [];
                            for (const [key, data] of Object.entries(attacher).filter(([key, _]) => key !== "lat" && key !== "long")) {
                                if (key !== "reL_GlobalID" && key !== "transtype" && key !== "auditor_Comments") {
                                    obj = {};
                                    obj["name"] = this.handleFormatKey(key);
                                    obj["content"] = data;
                                    obj["key"] = this.handleFormatKey(key);
                                    attacherSingle.push(obj);
                                }
                            }
                            convertedDataAttacher.push(attacherSingle);
                            //}
                        }
                    }
                }

                objPole["pole"] = convertedData;
                objPole["attacher"] = convertedDataAttacher;
                objPole["image"] = imageList;
                poleList.push(objPole)
            }
        }

        this.setState({
            poleList: poleList,
            haspermission: true,
            isloading: false
        });
    };
    render() {

        const columnsPole = [
            {
                title: 'Pole Information',
                dataIndex: 'name',
                colSpan: 4,
                key: 'name',
                render: (text) => <a className="nimbly-report-columnName">{text}</a>,
            },
            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
                colSpan: 0,
                render: (_, record) => (
                    record.name === "Coordinates" ?
                        <Space>
                            <a onClick={() => this.handleModalOpen(record.content)}>{record.content}</a>
                        </Space>
                        : record.content
                )
            },
            {
                title: 'Pole Information1',
                dataIndex: 'name1',
                colSpan: 0,
                key: 'name1',
                render: (text) => <a className="nimbly-report-columnName">{text}</a>,
            },
            {
                title: 'content1',
                dataIndex: 'content1',
                key: 'content1',
                colSpan: 0,
                render: (_, record) => (
                    record.name1 === "Coordinates" ?
                        <Space>
                            <a onClick={() => this.handleModalOpen(record.content)}>{record.content}</a>
                        </Space>
                        : record.content1
                )
            }
        ];

        const columnsAttacher = [
            {
                title: 'Attacher Information',
                dataIndex: 'name',
                colSpan: 2,
                key: 'name',
                render: (text) => <a className="nimbly-report-columnName">{text}</a>,
            },
            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
                colSpan: 0,
            }
        ];

        return (
            this.state.isloading ? <PageLoader /> :
                this.state.haspermission ?
                    <div>
                        <Row className="nimbly-table-view-contrainer-pdf">
                            {this.state.poleList == null ? null : this.state.poleList.map((item, index) =>
                                <Card style={{ width: "100%", margin: "10px" }}>
                                    <span className="nimbly-report-title">Report: {index + 1} of {this.state.poleList.length}</span>
                                    <Row>
                                        <Row style={{ width: "100%", margin: "10px", marginBottom: "5px" }}>
                                            <Table className="nimbly-table-view-table-pdf" columns={columnsPole} dataSource={item.pole} pagination={false} />
                                        </Row>
                                        <Row style={{ width: "100%", margin: "10px", marginBottom: "5px" }}>
                                            {item.attacher == null ? null : item.attacher.map((attacher) =>
                                                <Table className="nimbly-report-information-pdf" columns={columnsAttacher} dataSource={attacher} pagination={false} />
                                            )
                                            }
                                        </Row>


                                        <Row style={{ width: "100%", margin: "10px", marginBottom: "5px" }}>
                                            {item.image.map((image) => (
                                                <Card
                                                    style={{
                                                        width: 210,
                                                        //height: 160
                                                    }}
                                                    className="nimbly-image-card-pdf"
                                                >
                                                    <a rel="noreferrer" href={image} target="_blank">

                                                        <img
                                                            alt=""
                                                            src={image} style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                borderRadius: '5px'
                                                            }}
                                                        />
                                                    </a>

                                                </Card>
                                            ))
                                            }
                                        </Row>
                                    </Row>
                                </Card>

                            )
                            }


                        </Row>
                    </div>
                    : <NoAccess />
        );
    }

    async populateWeatherData(params) {
        //var we = params.we.replace('=', ' ').split(" ")[1];
        //var who = params.who.replace('=', ' ').split(" ")[1];
        //var globalid = params.globalid.replace('=', ' ').split(" ")[1];
        //var servicearea = params.servicearea.replace('=', ' ').split(" ")[1];

        var queryParamsDecoded = atob(params.report);

        var we = queryParamsDecoded.split("$")[0].replace('=', ' ').split(" ")[1];
        var who = queryParamsDecoded.split("$")[1].replace('=', ' ').split(" ")[1];
        var globalid = queryParamsDecoded.split("$")[2].replace('=', ' ').split(" ")[1];
        var servicearea = queryParamsDecoded.split("$")[3].replace('=', ' ').split(" ")[1];

        //console.log(we, who, globalid, servicearea);

        const response = await fetch('invoiceReportDENC', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            method: 'post',
            body: JSON.stringify(
                {
                    we: we == "null" ? "" : we,
                    //who: who == "null" ? "" : who,
                    globalid: globalid == "null" ? "" : globalid,
                    servicearea: servicearea == "null" ? "" : servicearea,
                }
            )
        })
        const data = await response.json();

        /// fitering useful poles
        //var originalData = data.poleList;

        //var searchResult = originalData;

        //if (globalid !== null && globalid !== "" && globalid !== "null" && globalid !== "undefined ") {
        //    searchResult = searchResult.filter(function (data) {
        //        return (data.globalID === globalid.toString())
        //    })
        //}

        //if (we !== null && we !== "" && we !== "null" && we !== "undefined ") {
        //    searchResult = searchResult.filter(function (data) {
        //        return (data.invoiceDate.replace('/', '').replace('/', '') === we.toString())
        //    })
        //}

        //if (servicearea !== null && servicearea !== "" && servicearea !== "null" && servicearea !== "undefined ") {
        //    searchResult = searchResult.filter(function (data) {
        //        return (data.district === servicearea.toString())
        //    })
        //}

        var email = this.props.currentUser.email.split("@")[1];
        if (email === "davey.com" || email === "spectrum.com" || email === "duke-energy.com" || email === "att.com") {
            var att = email.split(".")[0];
            this.handleDataConversion(data.poleList, att);
        }
    }
}

export default withRouter(InvoiceReport);