import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import PoleMap from './PoleMap'

//const options = {
//    zoomControlOptions: {
//        position: google.maps.ControlPosition.RIGHT_CENTER // 'right-center' ,
//        // ...otherOptions
//    }
//}

function GoogleMapLoader(props) {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyB1rmwwP3SKl8a47tX4BYPIIwQZsuWLZRQ",
        libraries : ['places']
    })

    const renderMap = () => {
        return <PoleMap poleDataProps={props.poleDataProps} handleMapFullSize={props.handleMapFullSize} fullSizeMap={props.fullSizeMap }/>
    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? renderMap() : null
}

export default React.memo(GoogleMapLoader)
