import { Space, Row, Col, Spin} from 'antd';
import React, { Component } from 'react';
import Chart from './chart'
import CompletedTable from './completedTable'
import ToCompletedTable from './toCompletedTable'
import GoogleMapLoader from './GoogleMapLoader';
import { PageLoader } from "./page-loader";

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isloading: false,
            fullSizeMap : false
        };
    }
    componentDidMount() {
    //    console.log("Map Data Received: ", this.props.poleDataProps);
    }

    finishLoadingChartData = () => {
        this.setState({
            loading: false
        });
    };

    handleMapFullSize = () => {
        this.setState({
            fullSizeMap: !this.state.fullSizeMap
        });
    };

    render() {
        return (
            this.state.isloading ? <PageLoader /> :
                <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>

                    <Row>
                        <Col span={this.state.fullSizeMap ? 24 : 17}>
                            <Row className={this.state.fullSizeMap ? "nimbly-chart-container-hidden" : "nimbly-chart-container"} >
                                <Chart
                                    //finishLoadingChartData={this.finishLoadingChartData()}
                                />
                            </Row>
                            <Row>
                                <Col span={24}>
                                    {/*{this.props.poleDataProps.poleList.length === 0 ? <Spin className="nimbly-map-spin" size="large" /> : null}*/}
                                    <Row><span className="nimbly-title-text-map">Map Viewer:</span> </Row>
                                    {/*<Row className={this.props.poleDataProps.poleList.length === 0 ? "nimbly-map-loading" : null}>*/}
                                        <GoogleMapLoader poleDataProps={this.props.poleDataProps} handleMapFullSize={this.handleMapFullSize} fullSizeMap={this.state.fullSizeMap} />
                                    {/*</Row>*/}
                                </Col>

                            </Row>
                        </Col>
                        <Col span={7} className={this.state.fullSizeMap ? "nimbly-transfer-table-container-hidden" : "nimbly-transfer-table-container"}>
                            <span className="nimbly-title-text-large">Transfer:</span>
                            <CompletedTable />
                            <ToCompletedTable />
                        </Col>
                    </Row>
                </Space>
        );
    }
}

export default Dashboard;
