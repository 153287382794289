import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Breadcrumb, Layout, Menu, theme, Col, Row } from 'antd';
import LogoutButton from './auth0logout';
import Home from './home'
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useParams, useMatch } from "react-router-dom";
import InvoiceReport from './invoiceReport'
import { NoAccess } from './noaccess';
import { NoAccessReport } from './noaccessreport';
import Landipage from './landingpage';

const CheckAuthentication = (props) => {
    const params = useParams();
    const { user, isAuthenticated, isLoading } = useAuth0();
    if (isLoading) {
        return <div>Loading ...</div>;
    }

    //var project = params.who.split("=")[1]
    //var reportPamas = params.project;
    //var queryParamsDecoded = atob(reportPamas);
    //console.log(reportPamas);
    //var project = queryParamsDecoded.split("$")[1].split("=")[1];
    //console.log(project); 

    if (isLoading) {
        return <div>Loading ...</div>;
    }
;
    //console.log(user);
    return (
        isAuthenticated ?
            (user.email.split("@")[1] === "davey.com" || user.email.split("@")[1] === "duke-energy.com" || user.email.split("@")[1] === "altafiber.com" ?
            <div>
                    {/*<Home showTab={props.showTab} />*/}
                    <Landipage userEmailDomain={user.email.split("@")[1]} userAuth0ID={user.sub}/>
            </div>
                :
                <NoAccessReport />)
            : <Navigate to="/login" replace />
    );
};

export default CheckAuthentication;