import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, Tooltip as AntdTooltip } from 'antd';
import { Col, Row, Checkbox, Select } from 'antd';
import {
    QuestionCircleOutlined
} from '@ant-design/icons'
export default class Chart extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            chartDataYear: null,
            chartDataMonth: null,
            loading: true,
            currentYear: 2023,
            showMonthly: true,
            showYearly: false,
            yearList: null,
            OptionList: null,
            maxYearCount: 0,
            maxMonthCount: 0
        };
    }

    componentDidMount() {
        this.populateChartData();
        //this.props.finishLoadingChartData();

    }


    onMonthlyCheckChange = (values) => {
        if (!this.state.showMonthly) {
            this.setState({
                showMonthly: true,
                showYearly: false,
                currentYear: 2023
            });
        }
    };

    onYearlyCheckChange = (values) => {
        if (!this.state.showYearly) {
            this.setState({
                showMonthly: false,
                showYearly: true,
            });
        }
    };

    handleChange = (value) => {
        this.setState({
            currentYear: value,
        })
    };

    render() {
        return (
            <Card className="nimbly-chart-card">
                <Row>
                    <Col span={4}><span className="nimbly-title-text">Poles Removed:</span><AntdTooltip title="SUB and DRG Poles Removed">
                        <QuestionCircleOutlined />
                    </AntdTooltip></Col>
                    <Col span={4} className="nimbly-chart-checkbox">
                        <Checkbox className="nimbly-chart-monthly-checkbox" checked={this.state.showMonthly} onChange={() => this.onMonthlyCheckChange()}>Month</Checkbox>
                        </Col>
                    <Col span={4} className="nimbly-chart-checkbox">
                        <Checkbox className="nimbly-chart-yearly-checkbox"  checked={this.state.showYearly} onChange={() => this.onYearlyCheckChange()}>Year</Checkbox>
                        </Col>
                    <Col span={4}></Col>
                    <Col span={8} className="nimbly-chart-year-selector-container">
                        <span style={{ marginRight:10 }}>View:</span>
                            <Select
                                disabled={this.state.showYearly}
                                style={{
                                    width: 120,
                            }}
                            className = "nimbly-chart-year-selector"
                                onChange={(e) => this.handleChange(e)}
                                options={this.state.OptionList}
                                value={this.state.showMonthly ? this.state.currentYear : 'All Years'}
                            />
                        </Col>
                    </Row>
                <ResponsiveContainer width="100%" height="100%">

                    <BarChart
                        //width={600}
                        //height={200}
                        data={this.state.showMonthly && this.state.chartDataMonth !== null ? this.state.chartDataMonth.filter(x => x.year == this.state.currentYear) : this.state.chartDataYear}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <XAxis dataKey={this.state.showMonthly ? "month" : "year"} />
                        <YAxis type="number" domain={[0, 2000]} tickCount={5} />
                        <Tooltip itemStyle={{ color: "black" }}  />
                        <Legend verticalAlign="top" height={36} />
                        <Bar dataKey="counts" fill={this.state.showMonthly ? "#FADB14" : "#1260CE"} barSize="200" radius={[15, 15, 0, 0]} />
                    </BarChart>
                </ResponsiveContainer>

                </Card>
        );
    }

    async populateChartData() {
        const response = await fetch('chartdefl');
        const data = await response.json();
        var yearlist = [];
        var countMonthlist = [];
        var countYearlist = [];

        data.polesRemovedMonth.forEach((x, i) => yearlist.includes(x.year) ? null : yearlist.push(x.year));

        data.polesRemovedMonth.forEach((x, i) => countMonthlist.push(x.counts));
        var largest = countMonthlist.sort((a, b) => a - b)[countMonthlist.length - 1];
        var maxCountMonth = largest == null || largest === "" ? 10 : parseInt(largest);

        
        data.polesRemovedYear.forEach((x, i) => countYearlist.push(x.counts));
        var largestYear = countYearlist.sort((a, b) => a - b)[countYearlist.length - 1];
        var maxCountYear = largestYear == null || largestYear === "" ? 10 : parseInt(largestYear);

        const OptionList = [];
        for (const key of yearlist.sort()) {
            var obj = {};
            obj["value"] = key;
            obj["label"] = key;
            OptionList.push(obj);
        }

        var MonthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
         var originMonthlyDate = data.polesRemovedMonth;

        var sampleMonthChartData = [];
        for (var year in yearlist) {
            var currentYear = yearlist[year];
            for (var month in MonthList) {
                var currentMonth = MonthList[month];
                var objSample = {};
                objSample["year"] = currentYear;
                objSample["month"] = currentMonth;
                objSample["counts"] = "0";
                sampleMonthChartData.push(objSample);

            }
        }

            for (var q in originMonthlyDate) {
                var realData = originMonthlyDate[q];
                var objIndex = sampleMonthChartData.findIndex((obj => obj.year == realData.year && obj.month == realData.month));
                sampleMonthChartData[objIndex].counts = realData.counts;
            }

        console.log(data);
        this.setState({
            chartDataYear: data.polesRemovedYear.filter(function (data) { return (data.year !== "2022") }),
            chartDataMonth: sampleMonthChartData,
            loading: false,
            yearList: yearlist.sort(),
            currentYear: yearlist.sort()[0],
            OptionList: OptionList,
            maxCountMonth: maxCountMonth,
            maxCountYear: maxCountYear
        });
    }
}
