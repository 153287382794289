import React from "react";
import { Space, Spin, Card, Span} from 'antd';
import image from "./img/noaccessimage.png";

export const NoAccessReport = () => {

    return (
        <div>
            <div className="nimbly-noaccess-container">
                <img alt="" className="nimbly-noaccess-image" src={image} />

                <Card className="nimbly-noaccess-message-container">
                    <h2 className="nimbly-noaccess-message-text">Please enter the report link in the browser address bar to view the report</h2>
                    <h3 className="nimbly-noaccess-message-text-content">Thank you for your understanding, we apologize for any inconvenience. Happy browsing!</h3>
                </Card>
            </div>
        </div>
    );
};