import React from 'react';
import ReactDOM from 'react-dom/client';
import './app.css';
//import './style/StyleSheet.css';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { App } from './app';
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import { Switch, useHistory } from 'react-router'

const root = ReactDOM.createRoot(document.getElementById('root'));

//const history = useHistory();

const onRedirectCallback = (appState) => {
    //this.props.history.push(appState.returnTo);
    //console.log(this.props);
};

root.render(

    <Auth0Provider
        domain="dev-f3txc-mp.us.auth0.com"
        // dev
        //clientId="oz5DdWpWgykV6AqzEJDZdHdnzuUGJOqt"
        //stage
        //clientId="YzqZ3NxiRVPnGeBOwzInFKqreaTx80Bf"
        // prod
        clientId="M3z82HJLp05SVEukU92NWRshFkeGgrsM"

        authorizationParams={{
            redirect_uri: window.location.origin
        }}

        //onRedirectCallback={onRedirectCallback}

    >
        <HashRouter>

                <App />
        </HashRouter>

    </Auth0Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
