import React, { Component } from 'react';
import { GoogleMap, LoadScript, MarkerF, Autocomplete, InfoWindow } from '@react-google-maps/api';
import ReportViewTable from './reportviewtable'
import { Card, Checkbox, Row, Drawer, Button, Spin, Tooltip } from 'antd';
import pin from './img/pin.png';
import ComplexTransfer from './img/ComplexTransfer.png';
import IdlePoles from './img/IdlePoles.png';
import LocationsCompleted from './img/LocationsCompleted.png';
import LocationsNotAudited from './img/LocationsNotAudited.png';
import LocationsWaiting from './img/LocationsWaiting.png';
import SimpleTransfers from './img/SimpleTransfers.png';
import { DoubleRightOutlined, DoubleLeftOutlined, FullscreenOutlined, FullscreenExitOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ReportView from './reportview'
import moment from 'moment';

const mapOptions = {
    fullscreenControl: false,
};


const containerStyle = {
    width: '100%',
    height: '48vh',
    borderRadius: '10px',
    borderColor: '#BFBFBF'
}

const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15,
    color: 'black',
    fontSize: "24px"
}

const containerStyleFullSize = {
    width: '100%',
    height: '81vh',
    borderRadius: '10px',
    borderColor: '#BFBFBF'
}

class PoleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapData: [],
            currentSelectedPole: null,
            currentCenter: {
                lat: 39.7684,
                lng: -86.1581
            },
            showInfoWindow: true,
            checkedMaplegend: "No Status",
            originalData: null,
            showDrawer: true,
            currentPin: "default",
            loading: true,
            map: {}
        };
        this.autocomplete = null

        this.onLoad = this.onLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
        this.handleMarkerOnClick = this.handleMarkerOnClick.bind(this)
        this.handleMapOnClick = this.handleMapOnClick.bind(this)
        this.handlePoleonChange = this.handlePoleonChange.bind(this)

    }

    componentDidMount() {
        //this.populateMapData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.poleDataProps !== prevProps.poleDataProps) 
        {
            //this.populateMapData();
            //console.log("Map Data Received: ", this.props.poleDataProps.poleList);
        }
    } 
    handlePoleonChange(e, type, pinType) {

        var locationWaitingStatusList = ["Pole not Set", "Electric Power", "Heavy Vegetation", "Pole Needs Topping", "No Access"]
        var updatedMapData;
        if (type === this.state.checkedMaplegend) {

            this.setState({
                checkedMaplegend: "No Status",
                currentPin: "default",
                currentSelectedPole: null,
                loading:true
            }, () => {
                let boundInput = this.getBounds();
                this.populateMapData(boundInput, "No Status");
            })
        }
        else {
            //if (type === "Complex Transfers") {
            //    updatedMapData = this.state.originalData.filter(function (data) {
            //        return data.info.poleStatus === type
            //    })
            //}
            //else if (type === "Locations Waiting") {
            //    updatedMapData = this.state.originalData.filter(function (data) {
            //        return data.info.poleStatus === "Pole not Set" || data.info.poleStatus === "Electric Power" || data.info.poleStatus === "Heavy Vegetation" || data.info.poleStatus === "Pole Needs Topping" || data.info.poleStatus === "No Access"
            //    })
            //}
            //else if (type === "Locations Completed") {
            //    updatedMapData = this.state.originalData.filter(function (data) {
            //        return data.info.poleStatus === "Pulled"
            //    })
            //}
            //else if (type === "Idle Poles") {
            //    updatedMapData = this.state.originalData.filter(function (data) {
            //        return data.info.transferType === "Idle"
            //    })
            //}
            //else if (type === "Simple Transfers") {
            //    updatedMapData = this.state.originalData.filter(function (data) {
            //        return data.info.transferType === "Simple Transfer"
            //    })
            //}
            this.setState({
                checkedMaplegend: type,
                currentSelectedPole: null,
                currentPin: pinType,
                loading: true
            }, () => {
                let boundInput = this.getBounds();
                this.populateMapData(boundInput, type);
            })
        }

    }

    handleMarkerOnClick(marker, e) {
        this.setState({
            currentSelectedPole: marker,
            showInfoWindow: true
        })
    }

    handleMapOnClick() {
        this.setState({
            currentSelectedPole: null,
        })
    }

    onLoad(autocomplete) {
        this.autocomplete = autocomplete
    }


    onPlaceChanged() {
        //console.log("asdad");
        if (this.autocomplete !== null) {
            //console.log("1");

            var searchCallback = this.autocomplete.getPlace();
            var searchInput = searchCallback.name.toString();
            var lookupData = this.state.mapData;
            var test = lookupData.filter(function (data) {
                return lookupData !== null ? data.info.poleNumber === searchInput : null
            });
            test = lookupData.filter(function (data) {
                return lookupData !== null ? data.info.coordinates === searchInput : null
            });
            //console.log("2");

            this.setState({
                currentSelectedPole: test.length === 0 ? null : test[0]
            })

            //console.log(this.autocomplete.getPlace(), test[0])

        } else {
            //console.log('Autocomplete is not loaded yet!')
        }
    }

    onMarkerClick = (props, marker) => {

    }

    showDrawer = () => {
        this.setState({
            showDrawer: !this.state.showDrawer
        })
    }


    getBounds = () => {
        let bounds = this.state.map.getBounds();
        let center = this.state.map.getCenter()

        let lat = center.lat();
        let long = center.lng();

        let ne = bounds.getNorthEast(); // LatLng of the north-east corner
        let sw = bounds.getSouthWest(); // LatLng of the south-west corner



        let bbox = {
            north: ne.lat(),
            east: ne.lng(),
            south: sw.lat(),
            west: sw.lng(),
            lat: lat,
            long: long
        };

        return bbox;
    }

    boundsCallBack = () => {
        this.setState({
            loading: true
        })
        setTimeout(() => {
            let boundInput = this.getBounds();
            this.populateMapData(boundInput, this.state.checkedMaplegend);
        }, 500)
    }


    boundsZoomCallBack = () => {

        setTimeout(() => {
            this.setState({
                loading: true
            })
            let boundInput = this.getBounds();
            this.populateMapData(boundInput, this.state.checkedMaplegend);
        }, 1000)
    }

    handleMapLoad = (map) => {
        this.setState((state) => ({ ...state, map }));
        setTimeout(() => {
            let boundInput = this.getBounds();
            this.populateMapData(boundInput, this.state.checkedMaplegend);
        }, 500)
    }

    render() {

        return (
            //<LoadScript
            //    //id="script-loader"
            //    googleMapsApiKey="AIzaSyB1rmwwP3SKl8a47tX4BYPIIwQZsuWLZRQ"
            //    libraries={libraries}
            //    preventGoogleFontsLoading={true}
            //>  
            <div className={this.state.loading ? "nimbly-map-loading" : null} >
                {this.state.loading ? <Spin className="nimbly-map-spin" size="large" /> : null}
                <GoogleMap
                    mapContainerStyle={this.props.fullSizeMap ? containerStyleFullSize : containerStyle}
                    center={this.state.currentCenter}
                    zoom={10}
                    //className="no-close-button-on-info-windows"
                    onClick={() => this.handleMapOnClick()}
                    options={mapOptions}
                    onDragEnd={this.boundsCallBack}
                    onLoad={this.handleMapLoad}
                    onZoomChanged={this.boundsZoomCallBack}
                    onFail={error => console.log(error)}
                >
                    <Autocomplete
                        onLoad={this.onLoad}
                        onPlaceChanged={this.onPlaceChanged}
                            //onFail={error => console.log(error)}
                    >
                        <input
                            type="text"
                            placeholder="Search by Pole Number/Address/Coordinates"
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width: `300px`,
                                height: `32px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                left: "50%",
                                marginLeft: "-120px",
                                marginTop: "10px"
                            }}
                        />
                </Autocomplete>
                {this.state.mapData == null && this.state.mapData.length <= 0 ? <Spin /> : this.state.mapData.map((item, index) => (
                        <MarkerF
                        key={index}
                        position={item.coords}
                        onClick={(e) => this.handleMarkerOnClick(item, e)}
                        icon={
                            this.state.currentPin === "ComplexTransfer" ? ComplexTransfer:
                                this.state.currentPin === "IdlePoles" ? IdlePoles:
                                    this.state.currentPin === "LocationsCompleted" ? LocationsCompleted:
                                        this.state.currentPin === "LocationsNotAudited" ? LocationsNotAudited:
                                                this.state.currentPin === "LocationsWaiting" ? LocationsWaiting:
                                                    this.state.currentPin === "SimpleTransfers" ? SimpleTransfers:
                                                        pin
                        }
                            //label={index.toString()}
                        >
                            {
                                this.state.currentSelectedPole !== null ? this.state.currentSelectedPole.info.poleNumber === item.info.poleNumber ?
                                    <InfoWindow
                                    key={index}
                                    position={item.coords}
                                    className="nimbly-map-infowindow"
                                    >
                                        <div
                                            style={divStyle}
                                        >
                                        {/*<ReportViewTable key={index} showPoleInfoList={[item.info]} dataSource="map" />*/}
                                        <ReportView showPoleInfoList={[item.info]} dataSource="map" />
                                        </div>
                                    </InfoWindow> : null : null
                            }

                        </MarkerF>
                        //<InfoWindowMap key={ index} item={item} index={index}/>
                    )
                    )
                    }

                <></>

                <Row //className={this.state.showDrawer ? "nimbly-draw-show" : "nimbly-draw-hidden"} 
                    className={this.props.fullSizeMap ? "nimbly-drawer-row-expanded" : "nimbly-drawer-row"} 
                >
                    <div
                        className="nimbly-drawer-container"
                    >
                                <Drawer
                                    placement="left"
                                    closable={false}
                                    getContainer={false}
                                    open={this.state.showDrawer}
                                    mask={false}
                        >
                                    <Card bordered={false} className="nimbly-map-legendbox">
                                <Checkbox className="nimbly-map-checkbox-SimpleTransfers" checked={this.state.checkedMaplegend === "Simple Transfer"} onChange={(e) => this.handlePoleonChange(e, "Simple Transfer", "SimpleTransfers")}>Simple Transfers</Checkbox>
                                <Tooltip title="All attachments on a pole that have not been transferred are simple transfer attachments">
                                    <InfoCircleOutlined />
                                </Tooltip>
                                <Checkbox className="nimbly-map-checkbox-ComplexTransfers" checked={this.state.checkedMaplegend === "Complex Transfers"} onChange={(e) => this.handlePoleonChange(e, "Complex Transfers", "ComplexTransfer")}>Complex Transfers</Checkbox>
                                <Tooltip title="There is at least 1 attachment on the pole marked as Complex">
                                    <InfoCircleOutlined />
                                </Tooltip>
                                {/*<Checkbox className="nimbly-map-checkbox-IdlePoles" checked={this.state.checkedMaplegend === "Idle Poles"} onChange={(e) => this.handlePoleonChange(e, "Idle Poles", "IdlePoles")}>Idle Poles</Checkbox>*/}
                                {/*<Tooltip title="There are no attachments on a pole OR all attachments on a pole have been transferred">*/}
                                {/*    <InfoCircleOutlined />*/}
                                {/*</Tooltip>*/}
                                <Checkbox className="nimbly-map-checkbox-LocationsComplete" checked={this.state.checkedMaplegend === "Locations Completed"} onChange={(e) => this.handlePoleonChange(e, "Locations Completed", "LocationsCompleted")}>Locations Completed</Checkbox>
                                <Tooltip title="Everything has been completed and pole has been pulled.">
                                    <InfoCircleOutlined />
                                </Tooltip>
                                <Checkbox className="nimbly-map-checkbox-LocationsWaiting" checked={this.state.checkedMaplegend === "Locations Waiting"} onChange={(e) => this.handlePoleonChange(e, "Locations Waiting", "LocationsWaiting")}>Locations Waiting</Checkbox>
                                <Tooltip title="These poles are waiting because of the following statuses: Heavy Vegetation, No Access, Pole Needs Topping, Electric Power, Pole Not Set">
                                    <InfoCircleOutlined />
                                </Tooltip>
                                {/*<Checkbox className="nimbly-map-checkbox-LocationsNotAudited" checked={this.state.checkedMaplegend === "Locations Not Audited"} onChange={(e) => this.handlePoleonChange(e, "Locations Not Audited", "LocationsNotAudited")}>Locations Not Audited</Checkbox>*/}
                                {/*{this.state.loading ? <Spin /> : null}*/}

                                <Row className="nimbly-map-info">
                                            {/*{this.state.checkedMaplegend !== null ? <span className="nimbly-map-info-text">Total: {this.state.mapData.length}</span> : <span className="nimbly-map-info-text">No Pole Status is Selected</span>}*/}
                                        </Row>
                                    </Card>
                                </Drawer>

                    </div>
                    <Button type="primary" onClick={() => this.showDrawer()} className={this.state.showDrawer ? "nimbly-drawer-show-button" : "nimbly-drawer-close-button"} >
                        {this.state.showDrawer ? <DoubleLeftOutlined /> : <DoubleRightOutlined /> }
                                </Button>

                </Row>
                <Button
                    type="primary"
                    onClick={() => this.props.handleMapFullSize()}
                    className={this.props.fullSizeMap ? "nimbly-map-expand-full-button" : "nimbly-map-expand-button"}
                >
                    {this.props.fullSizeMap ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                </Button>
                </GoogleMap>
            </div>
        )
    }

    async populateMapData(bounds, status) {
        //const response = await fetch('report', {
        //    headers: {
        //        'Accept': 'application/json',
        //        'Content-Type': 'application/json'
        //    },
        //    credentials: 'include',
        //    method: 'post',
        //    body: JSON.stringify(
        //        {
        //        }
        //    )
        //});
        //const data = await response.json();

        //const markerList = [];
        //if (this.props.poleDataProps !== null) {
        //    for (var index in this.props.poleDataProps.poleList) {
        //        var pole = this.props.poleDataProps.poleList[index];
        //        if (pole.auditDate !== null) {
        //            var convertedDataAudit = "9999-12-01";
        //            convertedDataAudit = moment(pole.auditDate.split(" ")[0]).format('yyyy-MM-DD')
        //            if (convertedDataAudit >= "2022-10-01") {
        //                var obj = {};
        //                var poleInfo = {};
        //                obj["lat"] = +pole.lat;
        //                obj["lng"] = +pole.long;
        //                poleInfo["info"] = this.props.poleDataProps.poleList[index];
        //                poleInfo["coords"] = obj;
        //                markerList.push(poleInfo);
        //            }
        //        }
        //    }
        //}

        //var markerListUpdated = markerList.filter(function (data) {
        //    return data.info.poleStatus !== "Not Doublewood" && data.info.poleStatus !== "Ready to Go" && data.info.poleStatus !== "Foreign Owened" && data.info.poleStatus !== "Added by Mistake" && data.info.poleStatus !== "Does Not Exist"
        //})


        //this.setState({
        //    mapData: markerListUpdated,
        //    originalData: markerListUpdated,
        //    loading: false,
        //});

            fetch("map", {
                headers: { "Content-Type": "application/json" },
                credentials: 'include',
                method: 'post',
                body: JSON.stringify({
                    north: bounds.north,
                    east: bounds.east,
                    south: bounds.south,
                    west: bounds.west,
                    lat: bounds.lat,
                    longt: bounds.long,
                    status: status 
                }
                )
            })
                .then(response => {
                    if (!response.ok) {
                        throw response;
                    }
                    return response.json();
                })
                .then(json => {
                    const markerList = [];
                    if (json !== null) {
                        for (var index in json.poleList) {
                            var pole = json.poleList[index];
                            if (pole.auditDate !== null) {
                                var convertedDataAudit = "9999-12-01";
                                convertedDataAudit = moment(pole.auditDate.split(" ")[0]).format('yyyy-MM-DD')
                                if (convertedDataAudit >= "2022-10-01") {
                                    var obj = {};
                                    var poleInfo = {};
                                    obj["lat"] = +pole.lat;
                                    obj["lng"] = +pole.long;
                                    poleInfo["info"] = json.poleList[index];
                                    poleInfo["coords"] = obj;
                                    markerList.push(poleInfo);
                                }
                            }
                        }
                    }

                    var markerListUpdated = markerList.filter(function (data) {
                        return data.info.poleStatus !== "Not Doublewood" && data.info.poleStatus !== "Idle" && data.info.poleStatus !== "Ready to Go" && data.info.poleStatus !== "Foreign Owened" && data.info.poleStatus !== "Added by Mistake" && data.info.poleStatus !== "Does Not Exist"
                    })
                    this.setState({
                        mapData: markerListUpdated,
                        originalData: markerListUpdated,
                        loading: false,
                    });
                }
                )
                .catch();


    }
}

export default PoleMap;
