import React, { Component } from 'react';
//import './style/StyleSheet.css';
import { Col, Row } from 'antd';
import { Space, Table, Tag, Card, Pagination, Button, Modal, Divider } from 'antd';
import { RollbackOutlined, InfoCircleOutlined } from '@ant-design/icons';
import MapModal from './mapModal';
import ReportView1 from './reportviewexpand'


const columnsPole = [
    {
        title: 'Pole Information',
        dataIndex: 'name',
        colSpan: 2,
        key: 'name',
        render: (text) => <a className="nimbly-report-columnName">{text}</a>,
    },
    {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        colSpan: 0,
    }
];

const columnsAttacher = [
    {
        title: 'Attacher Information',
        dataIndex: 'name',
        colSpan: 2,
        key: 'name',
        render: (text) => <a className="nimbly-report-columnName">{text}</a>,
    },
    {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        colSpan: 0,
    }
];

class ReportView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isauthenticated: false,
            currentPage: 1,
            originalData: null,
            locationTableData: null,
            poleInfoTableData: null,
            attacherData: null,
            totalReports: 0,
            imageList: [],
            imageCurrentShow: [],
            currentPolePage: 1,
            currentAttacher: null,
            currentAttacherPage: 1,
            isModalOpen: false,
            currentCoord: null
        };
    }

    componentDidMount() {
        //console.log("Report Data Received: ", this.props.showPoleInfoList);
        this.populateReportData(this.props.showPoleInfoList);
    }

    handleModalOpen = (coords) => {
        this.setState({
            isModalOpen: true,
            currentCoord: coords
        });
    }

    handleModalClose = () => {
        this.setState({
            isModalOpen: false,
            currentCoord: null
        });
    }

    handleFormatKey = (keyName) => {
        if (keyName === "address") {
            return "Address"
        }
        else if (keyName === "coordinates") {
            return "Coordinates"
        }
        else if (keyName === "owner") {
            return "Owner"
        }
        else if (keyName === "states") {
            return "State"
        }
        else if (keyName === "area") {
            return "Area"
        }
        else if (keyName === "poleNumber") {
            return "Pole Number"
        }
        else if (keyName === "duke_Pole_Number") {
            return "Duke Pole Number"
        }
        else if (keyName === "poleStatus") {
            return "Pole Status"
        }
        else if (keyName === "pullPoleType") {
            return "Pull Pole Type"
        }
        else if (keyName === "accessible") {
            return "Accessible"
        }
        else if (keyName === "restoration") {
            return "Restoration"
        }
        else if (keyName === "restorationDate") {
            return "Restoration Date"
        }
        //else if (keyName === "njuns") {
        //    return "NJUNS Number"
        //}
        else if (keyName === "removalDate") {
            return "Removal Date"
        }
        else if (keyName === "poleRemover") {
            return "Pole Remover"
        }
        else if (keyName === "auditDate") {
            return "Audit Date"
        }
        else if (keyName === "auditor") {
            return "Auditor"
        }
        else if (keyName === "comments") {
            return "Notes"
        }
        else if (keyName === "attcompany") {
            return "Attachment Company"
        }
        else if (keyName === "transtype") {
            return "Transfer Type"
        }
        else if (keyName === "transqty") {
            return "Transfer QTY"
        }
        else if (keyName === "auditor_Notes") {
            return "Auditor Notes"
        }
        else if (keyName === "crew_Notes") {
            return "Crew Notes"
        }
        else if (keyName === "restored") {
            return "Restored"
        }
        else if (keyName === "transdate") {
            return "Transfer Date"
        }
        else if (keyName === "transcrew") {
            return "Transfer Crew"
        }
        else if (keyName === "audit_Date") {
            return "Audit Date"
        }
        else if (keyName === "attachment_Status") {
            return "Attachment Status"
        }
        else if (keyName === "spansNum") {
            return "SPANS Number"
        }
        else if (keyName === "cincinnatiBell_DeliveryArea") {
            return "Cincinnati Bell Delivery Area"
        }
        //else if (keyName === "invoiceDate") {
        //    return "Invoice Date"
        //}
        //else if (keyName === "district") {
        //    return "District"
        //}
        //else if (keyName === "globalID") {
        //    return "GlobalID"
        //}
        else {
            return keyName
        }
    }

    handleDataConversion = (currentPoleIndex) => {
        var currentPole = this.props.showPoleInfoList[currentPoleIndex - 1];
        //console.log(this.props.showPoleInfoList);
        const convertedDataLocation = [];
        const convertedDataPole = [];
        var imageList = [];
        var convertedDataAttacher = [];
        var obj = {};
        const listofLocationInfo = ["address", "coordinates", "owner", "states", "area"];
        if (currentPole !== null && typeof currentPole !== 'undefined') {
            for (const [key, value] of Object.entries(currentPole).filter(([key, _]) => key !== "lat" && key !== "long")) {
                if (key !== "imagePath" && key !== "attachers" && key !== "globalID" && key !== "invoiceDate" && key !== "district" && key !== "njuns") {
                    obj = {};
                    obj["name"] = this.handleFormatKey(key);
                    obj["content"] = value;
                    obj["key"] = this.handleFormatKey(key);
                    if (listofLocationInfo.includes(key)) {
                        convertedDataLocation.push(obj);
                    } else {
                        convertedDataPole.push(obj);
                    }
                }
                else if (key === "imagePath") {
                    if (value != null) {
                        imageList = imageList.concat(value.split(","));

                    }
                }
            }
        }

        var startIndex = 0;
        var endIndex = imageList.length > 4 ? 4 : imageList.length;
        var slicedImage = imageList.slice(startIndex, endIndex);

        var Attachers = currentPole != null ? currentPole.attachers : null;
        if (Attachers != null && Attachers.length > 0) {
            for (var i in Attachers) {
                var attacher = Attachers[i];
                var attacherSingle = [];
                for (const [key, data] of Object.entries(attacher).filter(([key, _]) => key !== "lat" && key !== "long")) {
                    if (key !== "reL_GlobalID") {
                        obj = {};
                        obj["name"] = this.handleFormatKey(key);
                        obj["content"] = data;
                        obj["key"] = this.handleFormatKey(key);
                        attacherSingle.push(obj);
                    }
                }
                convertedDataAttacher.push(attacherSingle);
            }
        }

        this.setState({
            locationTableData: convertedDataLocation,
            poleInfoTableData: convertedDataPole,
            imageList: imageList,
            imageCurrentShow: slicedImage,
            attacherData: convertedDataAttacher,
            currentAttacher: convertedDataAttacher.length > 0 ? convertedDataAttacher[0] : null
        });
    };


    handleAttacherDataConversion = (currentPoleIndex) => {
        var Attachers = this.props.showPoleInfoList[currentPoleIndex].attachers;
        if (Attachers != null && Attachers.length > 0) {
            var convertedDataAttacher = [];
            var obj = {};
            for (var i in Attachers) {
                var attacher = Attachers[i];
                var attacherSingle = [];
                for (const [key, data] of Object.entries(attacher).filter(([key, _]) => key !== "lat" && key !== "long")) {
                    if (key !== "reL_GlobalID") {
                        obj = {};
                        obj["name"] = this.handleFormatKey(key);
                        obj["content"] = data;
                        obj["key"] = this.handleFormatKey(key);
                        attacherSingle.push(obj);
                    }
                }
                convertedDataAttacher.push(attacherSingle);
            }
        }
        this.setState({
            attacherData: convertedDataAttacher,
        });

    }
    handlePageChange = (pageNumber) => {
        this.handleDataConversion(pageNumber);
        this.setState({
            currentPage: 1,
            currentPolePage: pageNumber
        });
    }

    handleImagePageChange = (pageNumber) => {
        var startIndex = 0;
        var endIndex = this.state.imageList.length > 4 ? 4 : this.state.imageList.length;
        if (pageNumber !== 1) {

            startIndex = pageNumber * 4 - 4;
            endIndex = pageNumber * 4;
        }
        var slicedImage = this.state.imageList.slice(startIndex, endIndex);
        this.setState({
            imageCurrentShow: slicedImage,
            currentPage: pageNumber,
        })
    }

    handleAttacherPageChange = (pageNumber) => {
        this.setState({
            currentAttacher: this.state.attacherData == null ? null : this.state.attacherData[pageNumber - 1],
            currentAttacherPage: pageNumber
        })
    }


    render() {

        const columnsLocation = [
            {
                title: 'Location Details',
                dataIndex: 'name',
                colSpan: 2,
                key: 'name',
                render: (text) => <a className="nimbly-report-columnName">{text}</a>,
            },
            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
                colSpan: 0,
                render: (_, record) => (
                    record.name === "Coordinates" ?
                        <Space>
                            <a onClick={() => this.handleModalOpen(record.content)}>{record.content}</a>
                        </Space>
                        : record.content
                )
            }
        ];

        const columns = [
            {
                title: 'Pole Number',
                dataIndex: 'poleNumber',
                key: 'poleNumber',
                render: (text) => <span className="nimbly-queryreport-polenum">{text}</span>,
            },
            {
                title: 'Duke Pole Number',
                dataIndex: 'duke_Pole_Number',
                key: 'duke_Pole_Number',
            },
            {
                title: 'Restored',
                dataIndex: 'restored',
                key: 'restored',
            },
            {
                title: 'Pole Status',
                dataIndex: 'poleStatus',
                key: 'poleStatus',
            },
            {
                title: 'Pull Pole Type',
                dataIndex: 'pullPoleType',
                key: 'pullPoleType',
            },
            {
                title: 'Accessible',
                dataIndex: 'accessible',
                key: 'accessible',
                render: (_, record) => (
                    //console.log(record)
                    record.accessible === "Accessible" ?
                        <Tag color="green">{record.accessible}</Tag>
                        : <Tag color="orange">{record.accessible}</Tag>

                )            },
            {
                title: 'Restoration',
                dataIndex: 'restoration',
                key: 'restoration',
            },
            {
                title: 'Restoration Date',
                dataIndex: 'restorationDate',
                key: 'restorationDate',
            },
            {
                title: 'SPANS Number',
                dataIndex: 'spansNum',
                key: 'spansNum',
            },
            {
                title: 'Removal Date',
                dataIndex: 'removalDate',
                key: 'removalDate',
            },
            {
                title: 'Pole Remover',
                dataIndex: 'poleRemover',
                key: 'poleRemover',
            },
            {
                title: 'Audit Date',
                dataIndex: 'auditDate',
                key: 'auditDate',
            },
            {
                title: 'Auditor',
                dataIndex: 'auditor',
                key: 'auditor',
            },
            {
                title: 'Auditor Notes',
                dataIndex: 'auditor_Notes',
                key: 'auditor_Notes',
            },
            {
                title: 'Crew Notes',
                dataIndex: 'crew_Notes',
                key: 'crew_Notes',
            },
            {
                title: 'Cincinnati Bell Delivery Area',
                dataIndex: 'cincinnatiBell_DeliveryArea',
                key: 'cincinnatiBell_DeliveryArea',
            },
            {
                title: 'Attachment Status',
                dataIndex: 'attachment_Status',
                key: 'attachment_Status',
            }
        ];
        return (
            <div className="nimbly-report-table">
                {this.props.dataSource === "map" ? null : <Row>
                    <Button className="nimbly-report-goback-button" onClick={() => this.props.onReportGoback("General")} icon={<RollbackOutlined />}>Back</Button>
                </Row>
                }
                <Row className="nimbly-report-table">
                    <Table
                        size="small"
                        className="nimbly-report-table"
                        columns={columns}
                        dataSource={this.props.showPoleInfoList == null ? null : this.props.showPoleInfoList}
                        expandable={{
                            expandedRowRender: (record) => (
                                    <ReportView1 showPoleInfoList={this.props.showPoleInfoList.filter(function (data) {
                                        return (data.poleNumber === record.poleNumber)
                                    })} />
                            ),
                            rowExpandable: (record) => record.name !== 'Not Expandable',
                        }}
                        scroll={{ y: 550, x: 3000 }}
                    />
                </Row>
            </div>
        );
    }

    async populateReportData(formData) {
        this.setState({
            //originalData: formData,
            loading: false,
            totalReports: this.props.showPoleInfoList != null ? this.props.showPoleInfoList.length : 0,
        });
        this.handleDataConversion(1);
    }
}

export default ReportView;
