import React, { Component } from 'react';
import { Select, Space, Tag, Card, DatePicker, Spin } from 'antd';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const disabledDate = (current) => {
    return current < dayjs('2022-10-01');
};

class CompletedTable extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            isauthenticated: false,
            currentPage: "1",
            displayData: [],
            originalData: null,
            optionList: [],
            tranDataStart: "2022-10-01",
            tranDataEnd: "9999-01-25",
            loading:true
        };
    }

    componentDidMount() {
        this.populateTransferData();
    }

    handleChange = (value) => {

        var existCompany = this.state.displayData.filter(function (data) {
            return data.company === value
        })

        if (existCompany.length === 0) {
            var dataToAdd = this.state.originalData.filter(function (data) {
                return data.company === value
            })
            if (dataToAdd.length > 0) {
                this.setState(previousState => ({
                    displayData: [...previousState.displayData, dataToAdd[0]]
                }));
            }
        }

    };

    handleCloseTag = (tag) => {
        this.setState({
            displayData: this.state.displayData.filter(function (data) {
                return data.company !== tag
            })
        });
    };

    datepickeronChange = (date, dateString) => {
        if (dateString[0] === "") {
            this.setState({
                tranDataStart: "2022-10-01",
                tranDataEnd: "9999-01-25",
                loading: true
            }, () => {
                this.populateTransferDataDateRange();
            });
        }
        else {
            this.setState({
                tranDataStart: dateString[0],
                tranDataEnd: dateString[1],
                loading: true
            }, () => {
                this.populateTransferDataDateRange();
            });
        }
        } 

    render() {
        return (
            <div className="nimbly-trasfer-card-wapper-div">
                <Card className="nimbly-dashoboard-transfer-card">
                    <div className='nimbly-dashboard-transfer-selection'>
                        <Row style={{ marginBottom: 5 }}>
                            <Col span={12}>
                                <span className="nimbly-title-text-small">Completed</span>
                            </Col>
                            <Col span={12} className="nimbly-transfer-dropdown">
                                <Select
                                defaultValue="Select Company"
                                className="nimbly-chart-year-selector"
                                style={{
                                    width: 150,
                                }}
                                onChange={(value) => this.handleChange(value)}
                                options={this.state.optionList}
                                /></Col>
                            <RangePicker className="nimbly-transfer-datepicker" onChange={(date, dateString) => this.datepickeronChange(date, dateString)} disabledDate={disabledDate}/>

                        </Row>
                        <Space size={[0, 8]} wrap >
                                    {this.state.displayData == null ? null : this.state.displayData.map((item, index) => (
                                        <Tag className="nimbly-dashboard-selection-tag" key={item.company+"compTag"} closable onClose={() => this.handleCloseTag(item.company)}>
                                            {item.company}
                                        </Tag>
                                    ))}

                        </Space>
                        <Row style={{ marginTop: 5 }}>
                            <Col span={12}><span className="nimbly-title-text-small">Company</span></Col>
                            <Col span={12}><span className="nimbly-title-text-small">Location</span></Col>
                        </Row>
                    </div>
                    {this.state.loading ? <Space className="nimbly-loading-icon-transfer">
                        <Spin size="large">
                            <div className="content" />
                        </Spin>
                    </Space> :
                        <div className='nimbly-dashboard-transfer-table'>

                            {this.state.displayData == null ? null : this.state.displayData.map((item, index) => (
                                <Row key={item.company + "compComrow"}  className="nimbly-transfer-row">
                                    <Col span={12} key={item.company + "compCom"}>{item.company}</Col>
                                    <Col span={12} key={item.company + "compLoc"}>{item.locations}</Col>
                                </Row>
                            ))}
                        </div>
                        }


                </Card>
            </div>
        );
    }

    async populateTransferData() {

        fetch("transferdenc", {
            headers: { "Content-Type": "application/json" },
            credentials: 'include',
            method: 'post',
            body: JSON.stringify({
                TRANSDATEStart: this.state.tranDataStart,
                TRANSDATEEnd: this.state.tranDataEnd,
            }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then(json => {
                    const dataToUse = json.transferCompletd;
                    const OptionList = [];
                    for (const key of dataToUse) {
                        var obj = {};
                        obj["value"] = key.company;
                        obj["label"] = key.company;
                        OptionList.push(obj);
                    }

                    var emptyState = [];
                var defautCompanies = [
                    {
                        company: "CenturyLink",
                        locations: "0"
                    },
                    {
                        company: "Spectrum",
                        locations: "0"
                    },
                    {
                        company: "AT&T",
                        locations: "0"
                    },
                    {
                        company: "Frontier (FKA Verizon)",
                        locations: "0"
                    },
                    {
                        company: "Electric Power",
                        locations: "0"
                    },
                ];
                for (var index in defautCompanies) {
                    for (var i in dataToUse) {
                        var current = dataToUse[i];
                        if (defautCompanies[index].company === current.company) {
                            defautCompanies[index].locations = current.locations;
                            break;
                        } else {
                            defautCompanies[index].locations = "0"
                        }
                    }
                }

                    //for (var index in defautCompanies) {
                    //    var currentData = defautCompanies[index];

                    //    var dataToAdd = dataToUse.filter(function (data) {
                    //        return data.company === currentData
                    //    })
                    //    if (dataToAdd.length > 0) {
                    //        emptyState.push(dataToAdd[0]);
                    //    }
                    //}

                    this.setState({
                        originalData: dataToUse,
                        optionList: OptionList,
                        displayData: defautCompanies,
                        loading: false
                    });
                }
            )
            .catch();
    }

    async populateTransferDataDateRange() {
        fetch("transferdenc", {
            headers: { "Content-Type": "application/json" },
            credentials: 'include',
            method: 'post',
            body: JSON.stringify({
                TRANSDATEStart: this.state.tranDataStart,
                TRANSDATEEnd: this.state.tranDataEnd,
            }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then(json => {
                const dataToUse = json.transferCompletd;
                const OptionList = [];
                for (const key of dataToUse) {
                    var obj = {};
                    obj["value"] = key.company;
                    obj["label"] = key.company;
                    obj["key"] = key.company+"key";
                    OptionList.push(obj);
                }

                var defautCompanies = this.state.displayData;

                for (var index in defautCompanies) {
                    if (dataToUse.length > 0) {
                        for (var i in dataToUse) {
                            var current = dataToUse[i];
                            if (defautCompanies[index].company === current.company) {
                                defautCompanies[index].locations = current.locations;
                                break;
                            } else {
                                defautCompanies[index].locations = "0"
                            }
                        }
                    }
                    else {
                        defautCompanies[index].locations = "0"
                    }
                }

                this.setState({
                    originalData: dataToUse,
                    optionList: OptionList,
                    displayData: defautCompanies,
                    loading: false
                });
            }
            )
            .catch(
        );
    }
}

export default CompletedTable;