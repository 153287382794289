import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Breadcrumb, Layout, Menu, theme, Col, Row } from 'antd';
import LogoutButton from './auth0logout';
import Home from './home'
import { Navigate, useNavigate, useLocation, useParams, useMatch } from "react-router-dom";
import { NoAccess } from './noaccess';
import InvoiceReport from './invoiceReport'
import InvoiceReportDENC from './denc/invoiceReport'
import InvoiceReportDEFL from './defl/invoiceReport'

const CheckReportAuthentication = (props) => {
    //const navigate = useNavigate();
    //const location = useLocation();
    //const match = useMatch();
    const params = useParams();
    const { user, isAuthenticated, isLoading } = useAuth0();
    if (isLoading) {
        return <div>Loading ...</div>;
    }

    //var project = params.who.split("=")[1]
    var reportPamas = params.report;
    console.log(reportPamas);
    var queryParamsDecoded = atob(reportPamas);
    var we = queryParamsDecoded.split("$")[0].replace('=', ' ').split(" ")[1];
    var who = queryParamsDecoded.split("$")[1].replace('=', ' ').split(" ")[1];
    var globalid = queryParamsDecoded.split("$")[2].replace('=', ' ').split(" ")[1];
    var servicearea = queryParamsDecoded.split("$")[3].replace('=', ' ').split(" ")[1];
    console.log(we, who, globalid, servicearea);
    return (
        //isAuthenticated && (
        //    <div>
        //        <Home showTab={props.showTab} />
        //    </div>
        //)
        isAuthenticated ? 
            ((who === "demw" && (we !== "null" || globalid !== "null" || servicearea !== "null")) ? <InvoiceReport isAuthenticated={isAuthenticated} currentUser={user} /> : 
                ((who === "defl" && (we !== "null" || globalid !== "null" || servicearea !== "null")) ? <InvoiceReportDEFL isAuthenticated={isAuthenticated} currentUser={user} /> : 
                    ((who === "denc" && (we !== "null" || globalid !== "null" || servicearea !== "null")) ? <InvoiceReportDENC isAuthenticated={isAuthenticated} currentUser={user} /> : < NoAccess />))) :
            <Navigate to="/login" replace />
    );
};

export default CheckReportAuthentication;