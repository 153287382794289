import React, { Component } from 'react';
import { Button, Checkbox, Form, Input, Tooltip, Modal, Select } from 'antd';
import image from "./img/nimbly-login-background.png";
import './app.css';
import logo from './img/nimbly-login-logo-blue.png'; // with import
import SiderView from './sider';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './auth0login';

const { Option } = Select;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};


class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.state = {
            isauthenticated: false,
            visible: false,
            isSubmitted: false,
            firstname: null,
            lastname: null,
            email: null,
            region: null,
            project: null,
            disableButton: false
        };
    }

    componentDidMount() {
        //this.populateWeatherData();
    }

    handleFormSubmit = (values) => {
        const title = values.title;
        const content = values.content;
            this.setState({
        isauthenticated: true
    });
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
            disableButton: false,
            isSubmitted: false,
            firstname: null,
            lastname: null,
            email: null,
            region: null,
            project: null,

        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
            disableButton: false,
            isSubmitted: false,
            firstname: null,
            lastname: null,
            email: null,
            region: null,
            project: null,
        });
    };
    handleSubmit = () => {
        console.log("h");
        this.updateUserData();
        this.setState({
            isSubmitted: true,
            disableButton: true
        });
    };

    inputOnChangeAutoComp = (value, inputName) => {
        console.log(value, inputName);
        if (inputName === "region") {
            this.setState({
                region: value
            });
        }
        else if (inputName === "project") {
            this.setState({
                project: value
            });
        }
    }

    inputOnChange = (value, inputName) => {
        if (inputName === "firstname") {
            this.setState({
                firstname: value.target.value
            });
        }
        else if (inputName === "lastname") {
            this.setState({
                lastname: value.target.value
            });
        }
        else if (inputName === "email") {
            this.setState({
                email: value.target.value
            });
        }
    }
    render() {
        //const LoginButton = () => {
        //    const { loginWithRedirect } = useAuth0();

        //    return <button onClick={() => loginWithRedirect()}>Log In1</button>;
        //};

        return (
            this.state.isauthenticated ?
                <SiderView /> :
                <div className="nimbly-login-wrapper" style={{ backgroundImage: `url(${image})` }}>
                    <div className="nimbly-login-wrapper-left-container">
                        <div className="nimbly-login-login-window">
                            <img alt="" className="nimbly-login-logo" src={logo} />
                            <p className="nimbly-login-logo-hint-text">A PRE-NIMBLE SERVICE</p>
                            <div className="nimbly-login-button-container">
                                <p>WELCOME</p>
                                <LoginButton />
                                <Tooltip className = "nimbly-login-help-link" title="Need assistance accessing this site? Please reach out to your Davey Resource Group contact.">
                                    <span>I cannot sign-in</span>
                                </Tooltip>
                                <br />
                                <a type="primary" className="nimbly-login-help-link nimbly-registration-link" onClick={this.showModal}>
                                    Need to register
                                </a>
                                <Modal
                                    visible={this.state.visible}
                                    //onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={null}
                                >
                                    <h3>Sign up for Access:</h3>
                                    <Form onFinish={() => this.handleSubmit()} className="login-form" {...layout}>
                                        <Form.Item
                                            label="First Name"
                                            name="firstname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your first name!',
                                                },
                                            ]}
                                        >
                                            <Input onChange={(value) => this.inputOnChange(value, "firstname")} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Last Name"
                                            name="lastname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your last name!',
                                                },
                                            ]}
                                        >
                                            <Input onChange={(value) => this.inputOnChange(value, "lastname")} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your email!',
                                                },
                                            ]}
                                        >
                                            <Input onChange={(value) => this.inputOnChange(value, "email")} />
                                        </Form.Item>
                                        <Form.Item
                                            name="Project"
                                            label="Project"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Select a Project"
                                                //onChange={}
                                                allowClear
                                                onSelect={(value, option) => this.inputOnChangeAutoComp(value, "project")}
                                            >
                                                <Option value="Duke Energy">Duke Energy</Option>
                                            </Select>
                                        </Form.Item>
                                            <Form.Item
                                                name="region"
                                                label="Region"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Select a Region"
                                                    //onChange={}
                                                allowClear
                                                onSelect={(value, option) => this.inputOnChangeAutoComp(value, "region")}
                                                >
                                                    <Option value="DEMW">Duke Midwest</Option>
                                                <Option value="DENC">Duke North Carolina</Option>
                                                <Option value="DEFL">Duke Florida</Option>

                                                </Select>
                                        </Form.Item>
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                            }}
                                        >
                                            <Button disabled={this.state.disableButton} type="primary" htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    {this.state.isSubmitted ? <span>Your request has been sent, please wait for an email from the Site Admistrator to complete your registration.</span>  : null}
                                </Modal>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }

    async updateUserData() {
        fetch("userregister", {
            headers: { "Content-Type": "application/json" },
            credentials: 'include',
            method: 'post',
            body: JSON.stringify({
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                region: this.state.region,
                project: this.state.project

            }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }

                return response.json();
            })
            .then(json => {

            }
            )
            .catch();
    }
}

export default Login;
