import { CheckCircleOutlined, BookOutlined, UsergroupAddOutlined, CheckSquareOutlined, CloseSquareOutlined, SettingOutlined, SearchOutlined } from '@ant-design/icons';
import { Space, Table, Tag, Card, Pagination, Button, Modal, Input, Row, Col, Checkbox } from 'antd';
import logoblue from './img/logo-blue.png'; // with import
import delogo from './img/deLogo.png'; // with import
import React, { Component } from 'react';
import DEMWQueryWizard from './querywizard';
import DEMWDashboard from './dashborad';
import DENCQueryWizard from './denc/querywizard';
import DENCDashboard from './denc/dashborad';
import Profile from './profile';
import { Link } from "react-router-dom";
import { PageLoader } from "./page-loader";
import Highlighter from 'react-highlight-words';
const { CheckableTag } = Tag;

//const tagsData = ['Administrator', 'Member', 'Visitor'];
const tagsData = ['Administrator', 'Member'];

class Usermanagement extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {
            loading: true,
            userData: null,
            visible: false,
            currentSelectedEmail: null,
            currentSelectedName: null,
            currentSelectedFirstName: null,
            currentSelectedLastName: null,
            currentSelectedUserID: null,
            currentSelectedpwd: null,
            currentSelectedImg: null,
            operationType:null,
            removevisible: false,
            totaluser: 0,
            searchText: '',
            searchedColumn: '',
            editvisible: false,
            buttontype: null,
            selectedTags: ['Member'],
            setSelectedTags: ['Member'],
            refreshloading: false,
            checkedInfo: false,
            denyvisible: false
        };
    }

    componentDidMount() {
        //this.setState({
        //    currentPage: this.props.showTab
        //});

        this.populateUserData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentProject !== this.props.currentProject) {
            this.populateUserData();
            this.setState({
                loading: true,
            });
        }
    }

    onClick = (e) => {
        this.setState({
            currentPage: e.key
        });
    };

    showCreateModal = (content, operation) => {
        console.log(content);
        this.setState({
            visible: true,
            currentSelectedEmail: content.email == null ? null : content.email,
            currentSelectedName: content.name == null ? null : content.name,
            currentSelectedUserID: content.user_id == null ? null : content.user_id,
            operationType: operation == null ? null : operation,
            currentSelectedFirstName: content.name.split(" ")[0],
            currentSelectedLastName: content.name.split(" ")[1],
        });
    };

    showDenyModal = (content, operation) => {
        console.log(content);
        this.setState({
            denyvisible: true,
            currentSelectedEmail: content.email == null ? null : content.email,
            currentSelectedName: content.name == null ? null : content.name,
            currentSelectedUserID: content.user_id == null ? null : content.user_id,
            operationType: operation == null ? null : operation
        });
    };

    showRemoveModal = (content) => {
        console.log(content);
        this.setState({
            removevisible: true,
            removeEmail: content.email,
            removeName: content.name,
            removeuserid: content.user_id
        });
    };

    showEditModal = (operation, type, content) => {
        console.log(operation);
        if (content != null) {
            console.log(content.name.split(", "));
            var firstname, lastname;
            if (content.name.split(", ").length > 1) {
                firstname = content.name.split(", ")[1];
                lastname = content.name.split(", ")[0];
                console.log(firstname, lastname);
            }
            else {
                firstname = content.name.split(" ")[0];
                lastname = content.name.split(" ")[1];
                console.log(firstname, lastname);
            }
            this.setState({
                editvisible: true,
                currentSelectedEmail: content.email,
                currentSelectedFirstName: firstname,
                currentSelectedLastName: lastname,
                currentSelectedUserID: content.user_id,
                currentSelectedImg: content.picture,
                buttontype: type,
                operationType: operation == null ? null : operation,
                currentSelectedUserRole: content.role,
                selectedTags: [content.role]
            });
        }
        else {
            this.setState({
                editvisible: true,
                currentSelectedEmail: null,
                currentSelectedFirstName: null,
                currentSelectedLastName: null,
                currentSelectedUserID: null,
                currentSelectedImg: null,
                buttontype: type,
                operationType: operation == null ? null : operation
            });
        }

    };

    handleCreateOK = e => {
            this.setState({
                visible: false,
                refreshloading: true
            });
            this.addNewUser();

    };
    handleremoveOk = e => {
        this.setState({
            visible: false,
            refreshloading: true

        });
        this.removeUser();
    };
    handleDenyOk = e => {
        this.setState({
            denyvisible: false,
            refreshloading: true

        });
        this.addNewUser();
    };
    
    handleeditOk = e => {
        if (this.state.checkedInfo) {
            this.setState({
                editvisible: false,
                refreshloading: true

            });
            this.addNewUser();
        }

    };
    handleCancel = e => {
        this.setState({
            visible: false,
            removevisible: false,
            editvisible: false,
            denyvisible: false
        });
    };

    handleonChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    //icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined  type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
        this.setState({ searchText: '' });
    };

    handleChange = (tag, checked) => {
        const nextSelectedTags = checked
            ? [tag]
            : null;
        console.log('You are interested in: ', nextSelectedTags);
        //this.state.setSelectedTags(nextSelectedTags);
        this.setState({
            selectedTags: nextSelectedTags,
            currentSelectedUserRole: nextSelectedTags[0]
        }, () => console.log(this.state.selectedTags))

        
    };

    inputOnChange = (value, type) => {

        if (type === "firstname") {
            this.setState({
                currentSelectedFirstName: value.target.value,
            })        }
        else if (type === "lastname") {
            this.setState({
                currentSelectedLastName: value.target.value
            })        }
        else if (type === "email") {
            this.setState({
                currentSelectedEmail: value.target.value
            })
        }
        else if (type === "password") {
            this.setState({
                currentSelectedpwd: value.target.value
            })
        }
        //this.setState({
        //    currentSelectedName: this.state.currentSelectedFirstName + " " + this.state.currentSelectedLastName,
        //})
        console.log(this.state.currentSelectedFirstName, this.state.currentSelectedLastName, this.state.currentSelectedEmail, this.state.currentSelectedpwd)
    }

    checkonChange = (e) => {
        this.setState({
            checkedInfo: e.target.checked
        })
    };

    render() {

        const columnsLocation = [
            {
                ...this.getColumnSearchProps('name'),
                title: 'Name',
                dataIndex: 'picture',
                colSpan: 2,
                width: 100,
                key: 'picture',
                render: (text) => <img
                    alt=""
                    src={text} style={{
                        width: "50%",
                        height: "50%",
                        borderRadius: '50%'
                    }}
                />,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                colSpan: 0,
                render: (text) => <a className="nimbly-report-columnName">{text}</a>,
            },
            {
                ...this.getColumnSearchProps('email'),
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                render: (text) => <a className="nimbly-report-columnName">{text}</a>,
            },
            {
                title: 'User Role',
                dataIndex: 'role',
                key: 'role',
                render: (_, record) => (
                    record.status === "U" ?
                        <Space>
                            <a className="nimbly-textcolor-gray" onClick={() => this.showCreateModal(record, "Accept")}><CheckSquareOutlined className="nimbly-icon-green" />Accept</a><a className="nimbly-textcolor-gray" onClick={() => this.showDenyModal(record, "Deny")}><CloseSquareOutlined className="nimbly-icon-red" />Deny</a>
                        </Space>
                        : record.role === "Administrator" ? <Tag color="blue">{record.role}</Tag> : record.role === "Member" ? <Tag color="green">{record.role}</Tag> : record.role === "Visitor" ? <Tag color="yellow">{record.role}</Tag> : null
                )
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                render: (_, record) => (
                    record.status === "U" ? 
                                                <Space>
                            <a disabled className="nimbly-textcolor-lightgray" onClick={() => this.handleModalOpen(record)}><SettingOutlined className="nimbly-icon-gray-disable" />Modify Role</a><a className="nimbly-textcolor-lightgray" disabled onClick={() => this.showRemoveModal(record)}><CloseSquareOutlined className="nimbly-icon-lightgray-disable" />Remove User</a>

                        </Space> : 
                        <Space>
                            <a className="nimbly-textcolor-gray" onClick={() => this.showEditModal("Update", "Save", record)}><SettingOutlined className="nimbly-icon-gray" />Modify Role</a><a className="nimbly-textcolor-gray" onClick={() => this.showRemoveModal(record)}><CloseSquareOutlined className="nimbly-icon-red" />Remove User</a>

                        </Space>
                )
            },

        ];

        return (
            this.state.loading ? <PageLoader /> :
                <div>
                    <h3>Access Management</h3>
                    <p>{this.props.currentProject} - Access Management</p>
                    <br />

                    {
                        this.state.refreshloading ? <PageLoader /> : <div>
                        <Button className="nimbly-add-new-user" onClick={() => this.showEditModal("Create", "Add", null)} >Add New</Button>
                        <p className="nimbly-textcolor-gray nimbly-user-counts">Showing 10 of {this.state.totaluser} Users</p>
                        <Table onChange={this.handleonChange} className="nimbly-report-location nimbly-user-management" columns={columnsLocation} dataSource={this.state.userData == null ? null : this.state.userData} pagination={true} />
                    </div>
                            }
                    <div>
                        <Modal
                            title="Do you want to add this user?"
                            visible={this.state.visible}
                            onOk={this.handleCreateOK}
                            onCancel={this.handleCancel}
                        >
                            <p>Name: {this.state.currentSelectedName}</p>
                            <p>Email: {this.state.currentSelectedEmail}</p>
                            <p>Project: {this.props.currentProject}</p>
                        </Modal>
                    </div>
                    <div>
                        <Modal
                            title="Do you want to remove this user?"
                            visible={this.state.removevisible}
                            onOk={this.handleremoveOk}
                            onCancel={this.handleCancel}
                        >
                            <p>Name: {this.state.removeName}</p>
                            <p>Email: {this.state.removeEmail}</p>
                            <p>Project: {this.props.currentProject}</p>
                        </Modal>
                    </div>
                    <div>
                        <Modal
                            title="Do you want to deny this user?"
                            visible={this.state.denyvisible}
                            onOk={this.handleDenyOk}
                            onCancel={this.handleCancel}
                        >
                            <p>Name: {this.state.currentSelectedName}</p>
                            <p>Email: {this.state.currentSelectedEmail}</p>
                            <p>Project: {this.props.currentProject}</p>
                        </Modal>
                    </div>
                    <div>
                        <Modal
                            //title="Do you want to remove this user?"
                            visible={this.state.editvisible}
                            onOk={this.handleeditOk}
                            onCancel={this.handleCancel}
                            footer={null}
                        >
                            <Row justify="space-around" align="middle">
                                <Col className="nimbly-user-edit-window" span={6}>
                                    
                                    {this.state.buttontype === "Add" ? <Row className="nimbly-user-window-fontweighted">Name</Row> : 
                                        <Row justify="space-around" align="middle"><img
                                                alt=""
                                                src={this.state.currentSelectedImg} style={{
                                                    width: "50%",
                                                    height: "50%",
                                                    borderRadius: '50%'
                                                }}
                                        /></Row>
                                        }
                                    
                                    {/*<Row justify="space-around" align="middle">*/}
                                    {/*    edit*/}
                                    {/*</Row>*/}
                                </Col>
                                <Col className="nimbly-user-edit-window" span={9}><Input placeholder="Firstname" value={this.state.currentSelectedFirstName == null ? null : this.state.currentSelectedFirstName} onChange={(value) => this.inputOnChange(value, "firstname")} /></Col>
                                <Col className="nimbly-user-edit-window" span={9}><Input placeholder="Lastname" value={this.state.currentSelectedLastName == null ? null : this.state.currentSelectedLastName} onChange={(value) => this.inputOnChange(value, "lastname")} /></Col>
                            </Row>
                            <Row>
                                <Col className="nimbly-user-edit-window nimbly-user-window-fontweighted" span={6}>Email:</Col>
                                <Col className="nimbly-user-edit-window" span={18}><Input placeholder="Email" value={this.state.currentSelectedEmail == null ? null : this.state.currentSelectedEmail} onChange={(value) => this.inputOnChange(value, "email")} /></Col>
                            </Row>
                            <Row>
                                <Col className="nimbly-user-edit-window nimbly-user-window-fontweighted" span={6}>User Role:</Col>
                                <Col className="nimbly-user-edit-window" span={18}>
                                        <Row>
                                            <Col className="nimbly-user-edit-window" span={24}>
                                                {tagsData.map((tag) => (
                                                    <CheckableTag
                                                        key={tag}
                                                        checked={this.state.selectedTags.includes(tag)}
                                                        onChange={(checked) => this.handleChange(tag, checked)}
                                                    >
                                                        {tag}
                                                    </CheckableTag>
                                                ))}
                                            </Col>
                                        </Row>
                                        {/*<Row>*/}
                                        {/*<Col className="nimbly-user-edit-window nimbly-user-edit-window-icon" span={8}>*/}
                                        {/*    <Row  align="middle"><CheckCircleOutlined />Dashboard</Row>*/}
                                        {/*    <Row  align="middle"><CheckCircleOutlined />Query Wizard</Row>*/}
                                        {/*    <Row  align="middle"><CheckCircleOutlined />Report</Row>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                </Col>
                            </Row>
                            {/*<Row>*/}
                            {/*    <Col className="nimbly-user-edit-window" span={6}>Password:</Col>*/}
                            {/*    <Col className="nimbly-user-edit-window" span={18}><Input onChange={(value) => this.inputOnChange(value, "password")} /></Col>*/}
                            {/*</Row>*/}
                            <Row>
                                <Col span={6}></Col>
                                <Col className="nimbly-user-edit-window" span={18}><Checkbox onChange={(e) => this.checkonChange(e)}>All the above information is correct</Checkbox></Col>

                            </Row>
                            <Row>
                                <Col span={6}></Col>
                                <Col span={12}>
                                    <Button className="nimbly-add-new-user-window" onClick={() => this.handleeditOk()}>{this.state.buttontype}</Button>
                                </Col>
                                {/*<Col span={6}>Remove User</Col>*/}
                            </Row>
                        </Modal>
                    </div>
                </div>
        );
    }

    async populateUserData() {
        fetch("user", {
            headers: { "Content-Type": "application/json" },
            credentials: 'include',
            method: 'post',
            body: JSON.stringify({
                currentProject: this.props.currentProject == null ? "" : this.props.currentProject,
            }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then(json => {
                console.log(json);
                this.setState({
                    userData: json,
                    loading: false,
                    totaluser: json.length,
                    refreshloading: false
                });
            }
            )
            .catch();
    }

    async addNewUser() {
        console.log(this.props.currentProject);
        fetch("usercreate", {
            headers: { "Content-Type": "application/json" },
            credentials: 'include',
            method: 'post',
            body: JSON.stringify({
                currentProject: this.props.currentProject == null ? "" : this.props.currentProject,
                username: this.state.currentSelectedFirstName + " " + this.state.currentSelectedLastName,
                useremail: this.state.currentSelectedEmail == null ? "" : this.state.currentSelectedEmail,
                operationtype: this.state.operationType == null ? "" : this.state.operationType,
                password: this.state.currentSelectedpwd == null ? "" : this.state.currentSelectedpwd,
                userid: this.state.currentSelectedUserID == null ? "" : this.state.currentSelectedUserID,
                role: this.state.currentSelectedUserRole == null ? "" : this.state.currentSelectedUserRole
            }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                this.setState({
                    visible: false,
                    editvisible: false,
                    //refreshloading: false
                });
                this.populateUserData();
            })
            .then(json => {
                console.log(json);

            }
            )
            .catch();
    }

    async removeUser() {
        fetch("userdelete", {
            headers: { "Content-Type": "application/json" },
            credentials: 'include',
            method: 'post',
            body: JSON.stringify({
                userid: this.state.removeuserid == null ? "" : this.state.removeuserid
            }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                this.setState({
                    removevisible: false,
                    //refreshloading: false
                });
                this.populateUserData();
            })
            .then(json => {
                console.log(json);

            }
            )
            .catch();
    }
}

export default Usermanagement;
