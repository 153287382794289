import { Button, Form, Input, Radio, DatePicker, Alert, Modal, Card, AutoComplete } from 'antd';
import React, { Component } from 'react';
import { PageLoader } from "./page-loader";
import ReportView from "./querytable";
import ReportViewTable from './querytableattachers'


const { RangePicker } = DatePicker;

class QueryWizard extends Component {

    constructor(props) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.state = {
            isQuery: false,
            poleData: null,
            isGeneralReport: false,
            isTableReport: false,
            currentReportType: "General",
            RemovalStartDate: null,
            RemovalCompletedDate: null,
            AuditCompletedDate: null,
            AuditStartDate: null,
            PoleNumber: null,
            PoleStatus: null,
            PoleType: null,
            NjunNumber: null,
            SpanNumber: null,
            Restoration: null,
            City: null,
            Ragion: null,
            crewList: [],
            auditorList: [],
            crew: null,
            auditor: null,
            showPoleInfoList: [],
            showAlert: false,
            isloading: true,
            statusOptions: [],
            typeOptions: [],
            restorationOptions: [],
            cityOptions: [],
            regionOptions: [],
            openAutoCompleteDropdown: false,
            attCompany: null,
            attStartDate: null,
            attEndDate: null,
            attCompanyOptions: [],
        };
    }

    componentDidMount() {
        this.populateWizardData();
        console.log("Query Data Received: ", this.props.poleDataProps);
        //this.createAutoCompDropdowns(this.props.poleDataProps.poleList);
    }

    createAutoCompDropdowns = (originData) => {
        var statusList = [], typeList = [], restorationList = [], cityList = [], regionList = [], attCompanyList = []
        var found = false;
        var i = 0;
        var obj = {};

        for (var j in originData) {
            for (const [key, value] of Object.entries(originData[j])) {
                if (key === "poleStatus") {
                    // to support old browsers
                    found = false;
                    for (i = 0; i < statusList.length; i++) {
                        if (statusList[i].value === value) {
                            found = true;
                            break;
                        }
                    }
                    if (found === false) {
                        if (value !== null) {
                            obj = {};
                            obj["value"] = value;
                            obj["label"] = value;
                            obj["key"] = value;
                            statusList.push(obj);
                        }
                    }
                }
                else if (key === "pullPoleType") {
                    found = false;
                    for (i = 0; i < typeList.length; i++) {
                        if (typeList[i].value === value) {
                            found = true;
                            break;
                        }
                    }
                    if (found === false) {
                        if (value !== null) {
                            obj = {};
                            obj["value"] = value;
                            obj["label"] = value;
                            obj["key"] = value;
                            typeList.push(obj);
                        }
                    }
                }
                else if (key === "restoration") {
                    found = false;
                    for (i = 0; i < restorationList.length; i++) {
                        if (restorationList[i].value === value) {
                            found = true;
                            break;
                        }
                    }
                    if (found === false) {
                        if (value !== null) {
                            obj = {};
                            obj["value"] = value;
                            obj["label"] = value;
                            obj["key"] = value;
                            restorationList.push(obj);
                        }
                    }
                }
                else if (key === "area") {
                    found = false;
                    for (i = 0; i < cityList.length; i++) {
                        if (cityList[i].value === value) {
                            found = true;
                            break;
                        }
                    }
                    if (found === false) {
                        if (value !== null) {
                            obj = {};
                            obj["value"] = value;
                            obj["label"] = value;
                            obj["key"] = value;
                            cityList.push(obj);
                        }
                    }
                }
                else if (key === "states") {
                    found = false;
                    for (i = 0; i < regionList.length; i++) {
                        if (regionList[i].value === value) {
                            found = true;
                            break;
                        }
                    }
                    if (found === false) {
                        if (value !== null) {
                            obj = {};
                            obj["value"] = value;
                            obj["label"] = value;
                            obj["key"] = value;
                            regionList.push(obj);
                        }
                    }
                }
                else if (key === "attachers") {
                    var tempAttachers = originData[j].attachers;
                    for (var q in tempAttachers) {
                        for (const [key, value] of Object.entries(tempAttachers[q])) {
                            found = false;
                            for (i = 0; i < attCompanyList.length; i++) {
                                if (attCompanyList[i].value === value) {
                                    found = true;
                                    break;
                                }
                            }
                            if (found === false && key === "attcompany") {
                                if (value !== null) {
                                    obj = {};
                                    obj["value"] = value;
                                    obj["label"] = value;
                                    obj["key"] = value;
                                    attCompanyList.push(obj);
                                }
                            }
                        }
                    }


                }
            }
        }

        this.setState({
            statusOptions: statusList,
            typeOptions: typeList,
            restorationOptions: restorationList,
            cityOptions: cityList,
            regionOptions: regionList,
            attCompanyOptions: attCompanyList
        });
    }

    onReportTypeChange = (e) => {

        this.setState({
            currentReportType: e.target.value
        });
    };

    onReportGoback = (reportType) => {
        this.setState({
            isQuery: false,
            currentReportType: reportType,
            RemovalStartDate: null,
            RemovalCompletedDate: null,
            AuditCompletedDate: null,
            AuditStartDate: null,
            PoleNumber: null,
            PoleStatus: null,
            PoleType: null,
            NjunNumber: null,
            SpanNumber: null,
            Restoration: null,
            City: null,
            Ragion: null,
            crew: null,
            auditor: null,
            showPoleInfoList: [],
        });
    };

    //handleFormSubmit = () => {

    //    /// fitering useful poles
    //    var outerScope = this;
    //    var originalData = this.props.poleDataProps.poleList;

    //    var searchResult = originalData;
    //    console.log("Search init data: ", searchResult);

    //    if (outerScope.state.PoleNumber !== null && outerScope.state.PoleNumber !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            return (data.poleNumber === outerScope.state.PoleNumber.toString())
    //        })
    //    }

    //    if (outerScope.state.PoleStatus !== null && outerScope.state.PoleStatus !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            return (data.poleStatus === outerScope.state.PoleStatus.toString())
    //        })
    //    }

    //    if (outerScope.state.PoleType !== null && outerScope.state.PoleType !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            return (data.pullPoleType === outerScope.state.PoleType.toString())
    //        })
    //    }

    //    if (outerScope.state.NjunNumber !== null && outerScope.state.NjunNumber !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            return (data.njuns === outerScope.state.NjunNumber.toString())
    //        })
    //    }
    //    if (outerScope.state.SpanNumber !== null) {
    //        searchResult = searchResult.filter(function (data) {
    //            return (data.spansNum === outerScope.state.SpanNumber.toString())
    //        })
    //    }

    //    if (outerScope.state.Restoration !== null && outerScope.state.Restoration !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            return (data.restoration === outerScope.state.Restoration.toString())
    //        })
    //    }

    //    if (outerScope.state.City !== null && outerScope.state.City !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            return (data.area === outerScope.state.City.toString())
    //        })
    //    }
    //    if (outerScope.state.Ragion !== null && outerScope.state.Ragion !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            return (data.state === outerScope.state.Ragion.toString())
    //        })
    //    }

    //    if (outerScope.state.crew !== null && outerScope.state.crew !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            return (data.poleRemover === outerScope.state.crew.toString())
    //        })
    //    }

    //    if (outerScope.state.auditor !== null && outerScope.state.auditor !== "") {
    //        var prefix = "Kent\\";
    //        searchResult = searchResult.filter(function (data) {
    //            return (data.auditor === prefix + outerScope.state.auditor.toString())
    //        })
    //    }

    //    if (outerScope.state.RemovalStartDate !== null && outerScope.state.RemovalStartDate !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            var convertedData = "9999-12-01";
    //            if (data.removalDate !== null) {
    //                convertedData = moment(data.removalDate.split(" ")[0]).format('yyyy-MM-DD')
    //            }
    //            return (convertedData >= outerScope.state.RemovalStartDate.toString() && convertedData <= outerScope.state.RemovalCompletedDate.toString())
    //        })
    //    }

    //    if (outerScope.state.AuditStartDate !== null && outerScope.state.AuditStartDate !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            var convertedDataAudit = "9999-12-01";
    //            if (data.auditDate !== null) {
    //                convertedDataAudit = moment(data.auditDate.split(" ")[0]).format('yyyy-MM-DD')
    //            }
    //            return (convertedDataAudit >= outerScope.state.AuditStartDate.toString() && convertedDataAudit <= outerScope.state.AuditCompletedDate.toString())
    //        })
    //    }

    //    if (outerScope.state.attCompany !== null && outerScope.state.attCompany !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            var re = data.attachers == null ? false : data.attachers.filter(function (data) {
    //                return (data.attcompany === outerScope.state.attCompany.toString())
    //            });
    //            var result = re === true || re.length > 0 ? true : false;
    //            return (result)
    //        })
    //    }

    //    if (outerScope.state.attStartDate !== null && outerScope.state.attStartDate !== "") {
    //        searchResult = searchResult.filter(function (data) {
    //            var re = data.attachers == null ? false : data.attachers.filter(function (data) {
    //                var convertedDate = "9999-12-01";
    //                if (data.auditDate !== null) {
    //                    convertedDate = moment(data.audit_Date.split(" ")[0]).format('yyyy-MM-DD')
    //                }
    //                return (convertedDate >= outerScope.state.attStartDate.toString() && convertedDate <= outerScope.state.attEndDate.toString())
    //            });
    //            var result = re === true || re.length > 0 ? true : false;
    //            return (result)
    //        })
    //    }

    //    console.log("Search Result: ", searchResult);
    //    if (searchResult == null || searchResult.length === 0) {
    //        this.setState({
    //            showAlert: true,
    //        });
    //    }
    //    else {
    //        this.setState({
    //            isQuery: true,
    //            showPoleInfoList: searchResult
    //        });
    //    }

    //};

    handleFormSubmit = () => {
        //console.log(this.state);
        if ((this.state.PoleNumber == null || this.state.PoleNumber === "") &&
            (this.state.PoleStatus == null || this.state.PoleStatus === "") &&
            (this.state.PoleType == null || this.state.PoleType === "") &&
            (this.state.NjunNumber == null || this.state.NjunNumber === "") &&
            (this.state.SpanNumber == null || this.state.SpanNumber === "") &&
            (this.state.Restoration == null || this.state.Restoration === "") &&
            (this.state.City == null || this.state.City === "") &&
            (this.state.Ragion == null || this.state.Ragion === "") &&
            (this.state.crew == null || this.state.crew === "") &&
            (this.state.RemovalStartDate == null || this.state.RemovalStartDate === "") &&
            (this.state.RemovalCompletedDate == null || this.state.RemovalCompletedDate === "") &&
            (this.state.AuditStartDate == null || this.state.AuditStartDate === "") &&
            (this.state.auditor == null || this.state.auditor === "")
        ) {

            if ((this.state.AuditCompletedDate == null || this.state.AuditCompletedDate === "") &&
                (this.state.attCompany == null || this.state.attCompany === "") &&
                (this.state.attStartDate == null || this.state.attStartDate === "")) {
                this.setState({
                    showAlert: true,
                });
            }
            else {
                this.setState({
                    isloading: true,
                });
                this.populateAttcherResultData()
            }

        } else {
            this.setState({
                isloading: true,
            });

            this.populateResultData();
        }
    }

    datepickeronChange = (date, dateString, Field) => {
        if (Field === "RemovalDateRange") {
            this.setState({
                RemovalStartDate: dateString[0],
                RemovalCompletedDate: dateString[1]
            });
        } else if (Field === "AuditDateRange") {
            this.setState({
                AuditStartDate: dateString[0],
                AuditCompletedDate: dateString[1],

            });
        }
        else if (Field === "attAuditeDate") {
            this.setState({
                attStartDate: dateString[0],
                attEndDate: dateString[1],
            });
        }


    }
    AutoCompOnSearch = (value, inputName) => {
        this.inputOnChangeAutoComp(value, inputName)
    }

    AutoCompOnSelect = (value, option, inputName) => {
        this.inputOnChangeAutoComp(value, inputName)
    }

    inputOnChangeAutoComp = (value, inputName) => {
        if (inputName === "PoleNumber") {
            this.setState({
                PoleNumber: value
            });
        }
        else if (inputName === "PoleStatus") {
            this.setState({
                PoleStatus: value
            });
        }
        else if (inputName === "PoleType") {
            this.setState({
                PoleType: value
            });
        }
        else if (inputName === "NjunNumber") {
            this.setState({
                NjunNumber: value
            });
        }
        else if (inputName === "SpanNumber") {
            this.setState({
                SpanNumber: value
            });
        }
        else if (inputName === "Restoration") {
            this.setState({
                Restoration: value
            });
        }
        else if (inputName === "City") {
            this.setState({
                City: value
            });
        }
        else if (inputName === "Ragion") {
            this.setState({
                Ragion: value
            });
        }
        else if (inputName === "Auditor") {
            this.setState({
                auditor: value
            });
        }
        else if (inputName === "Crew") {
            this.setState({
                crew: value
            });
        }
        else if (inputName === "attCompany") {
            this.setState({
                attCompany: value
            });
        }

    }

    inputOnChange = (value, inputName) => {
        if (inputName === "PoleNumber") {
            this.setState({
                PoleNumber: value.target.value
            });
        }
        else if (inputName === "PoleStatus") {
            this.setState({
                PoleStatus: value.target.value
            });
        }
        else if (inputName === "PoleType") {
            this.setState({
                PoleType: value.target.value
            });
        }
        else if (inputName === "NjunNumber") {
            this.setState({
                NjunNumber: value.target.value
            });
        }
        else if (inputName === "SpanNumber") {
            this.setState({
                SpanNumber: value.target.value
            });
        }
        else if (inputName === "Restoration") {
            this.setState({
                Restoration: value.target.value
            });
        }
        else if (inputName === "City") {
            this.setState({
                City: value.target.value
            });
        }
        else if (inputName === "Ragion") {
            this.setState({
                Ragion: value.target.value
            });
        }
        else if (inputName === "attCompany") {
            this.setState({
                attCompany: value.target.value
            });
        }
    }

    handleCrewAuditorChange = (value, type) => {
        if (type === "Crew") {
            this.setState({
                crew: value
            });
        }
        else if (type === "Auditor") {
            this.setState({
                auditor: value
            });
        }
    }

    handleAletClose = () => {
        this.setState({
            showAlert: false
        });
    }
    render() {
        //const {...props } = this.state;
        return (
            this.state.isloading ? <PageLoader /> :
                this.state.isQuery && this.state.currentReportType === "General" ?
                    <ReportView showPoleInfoList={this.state.showPoleInfoList} onReportGoback={this.onReportGoback} /> : this.state.isQuery && this.state.currentReportType === "Table" ? <ReportViewTable showPoleInfoList={this.state.showPoleInfoList} dataSource="wizard" onReportGoback={this.onReportGoback} /> :
                        <Card className="nimbly-querywizard-container"> <Form
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            style={{ maxWidth: 600 }}
                            initialValues={{ remember: true }}
                            onFinish={(values) => this.handleFormSubmit(values)}
                            autoComplete="off"
                            className="nimbly-query-form"
                        >
                            <Form.Item label="Query Wizard" className="nimbly-querywizard-title">
                                <Radio.Group className="nimbly-wizard-radio" onChange={(e) => this.onReportTypeChange(e)} Value={this.state.currentReportType} defaultValue={this.state.currentReportType} optionType="button">
                                    <Radio className="nimbly-wizard-radio-button" value="General">Pole Info</Radio>
                                    <Radio className="nimbly-wizard-radio-button" value="Table">Attacher Info</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Removal Date"
                                className="nimbly-querywizard-title"
                            >
                            </Form.Item>
                            <Form.Item
                                label="Start Date"
                            >
                                <RangePicker className="nimbly-querywizard-input" onChange={(date, dateString) => this.datepickeronChange(date, dateString, "RemovalDateRange")} />
                            </Form.Item>

                            <Form.Item
                                label="Crew"
                            >
                                {/*<Select*/}
                                {/*    defaultValue="Select Crew"*/}
                                {/*    style={{*/}
                                {/*        width: 350,*/}
                                {/*    }}*/}
                                {/*    onChange={(value) => this.handleCrewAuditorChange(value, "Crew")}*/}
                                {/*    options={this.state.crewList}*/}
                                {/*    />*/}

                                <AutoComplete
                                    options={this.state.crewList}
                                    style={{
                                        width: 350,
                                    }}
                                    filterOption={(inputValue, option) =>
                                        option.value != null ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                                    }
                                    onSearch={(value) => this.AutoCompOnSearch(value, "Crew")}
                                    onSelect={(value, option) => this.AutoCompOnSelect(value, option, "Crew")}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Audit Date"
                                className="nimbly-querywizard-title"
                            >

                            </Form.Item>
                            <Form.Item
                                label="Start Date"
                            >
                                <RangePicker className="nimbly-querywizard-input" onChange={(date, dateString) => this.datepickeronChange(date, dateString, "AuditDateRange")} />
                            </Form.Item>
                            <Form.Item
                                label="Auditor"
                            >
                                {/*<Select*/}
                                {/*    defaultValue="Select Auditor"*/}
                                {/*    style={{*/}
                                {/*        width: 350,*/}
                                {/*    }}*/}
                                {/*    onChange={(value) => this.handleCrewAuditorChange(value, "Auditor")}*/}
                                {/*    options={this.state.auditorList}*/}
                                {/*    />*/}
                                <AutoComplete
                                    options={this.state.auditorList}
                                    style={{
                                        width: 350,
                                    }}
                                    filterOption={(inputValue, option) =>
                                        option.value != null ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                                    }
                                    onSearch={(value) => this.AutoCompOnSearch(value, "Auditor")}
                                    onSelect={(value, option) => this.AutoCompOnSelect(value, option, "Auditor")}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Pole Number"
                            //rules={[{ required: true, message: 'Please input the Pole Number!' }]}
                            //tooltip="What do you want others to call you?"
                            >
                                <Input className="nimbly-querywizard-input" onChange={(value) => this.inputOnChange(value, "PoleNumber")} />
                            </Form.Item>

                            <Form.Item
                                label="Pole Status"
                            >
                                {/*<Input onChange={(value) => this.inputOnChange(value, "PoleStatus")} />*/}
                                <AutoComplete
                                    options={this.state.statusOptions}
                                    style={{
                                        width: 350,
                                    }}
                                    filterOption={(inputValue, option) =>
                                        option.value != null ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                                    }
                                    onSearch={(value) => this.AutoCompOnSearch(value, "PoleStatus")}
                                    onSelect={(value, option) => this.AutoCompOnSelect(value, option, "PoleStatus")}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Pole Type"
                            >
                                {/*<Input onChange={(value) => this.inputOnChange(value, "PoleType")} />*/}
                                <AutoComplete
                                    options={this.state.typeOptions}
                                    style={{
                                        width: 350,
                                    }}
                                    filterOption={(inputValue, option) =>
                                        option.value != null ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                                    }
                                    onSearch={(value) => this.AutoCompOnSearch(value, "PoleType")}
                                    onSelect={(value, option) => this.AutoCompOnSelect(value, option, "PoleType")}
                                />
                            </Form.Item>

                            <Form.Item
                                label="NJUNS Number"
                            >
                                    <Input className="nimbly-querywizard-input" onChange={(value) => this.inputOnChange(value, "NjunNumber")} />
                            </Form.Item>

                            {/*<Form.Item*/}
                            {/*    label="SPANS Number"*/}
                            {/*>*/}
                            {/*    <Input className="nimbly-querywizard-input" onChange={(value) => this.inputOnChange(value, "SpanNumber")} />*/}
                            {/*</Form.Item>*/}

                            <Form.Item
                                label="Restoration"
                            >
                                {/*<Input onChange={(value) => this.inputOnChange(value, "Restoration")} />*/}
                                <AutoComplete
                                    options={this.state.restorationOptions}
                                    style={{
                                        width: 350,
                                    }}
                                    filterOption={(inputValue, option) =>
                                        option.value != null ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                                    }
                                    onSearch={(value) => this.AutoCompOnSearch(value, "Restoration")}
                                    onSelect={(value, option) => this.AutoCompOnSelect(value, option, "Restoration")}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Area"
                            >
                                {/*<Input onChange={(value) => this.inputOnChange(value, "City")} />*/}
                                <AutoComplete
                                    options={this.state.cityOptions}
                                    style={{
                                        width: 350,
                                    }}
                                    filterOption={(inputValue, option) =>
                                        option.value != null ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                                    }
                                    onSearch={(value) => this.AutoCompOnSearch(value, "City")}
                                    onSelect={(value, option) => this.AutoCompOnSelect(value, option, "City")}
                                />
                            </Form.Item>
                            <Form.Item
                                label="State"
                            >
                                {/*<Input onChange={(value) => this.inputOnChange(value, "Ragion")} />*/}
                                <AutoComplete
                                    options={this.state.regionOptions}
                                    style={{
                                        width: 350,
                                    }}
                                    filterOption={(inputValue, option) =>
                                        option.value != null ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                                    }
                                    onSearch={(value) => this.AutoCompOnSearch(value, "Ragion")}
                                    onSelect={(value, option) => this.AutoCompOnSelect(value, option, "Ragion")}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Attachments"
                                className="nimbly-querywizard-title"
                            >
                            </Form.Item>
                            <Form.Item
                                label="Attachment Company"
                            >
                                {/*<Input className="nimbly-querywizard-input" onChange={(value) => this.inputOnChange(value, "attCompany")} />*/}
                                <AutoComplete
                                    options={this.state.attCompanyOptions}
                                    style={{
                                        width: 350,
                                    }}
                                    filterOption={(inputValue, option) =>
                                        option.value != null ? option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                                    }
                                    onSearch={(value) => this.AutoCompOnSearch(value, "attCompany")}
                                    onSelect={(value, option) => this.AutoCompOnSelect(value, option, "attCompany")}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Audit Date"
                            >
                                <RangePicker className="nimbly-querywizard-input" onChange={(date, dateString) => this.datepickeronChange(date, dateString, "attAuditeDate")} />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="nimbly-querywizard-bottonrow">
                                <Button type="primary" htmlType="submit">
                                    Execute Query
                                </Button>
                            </Form.Item>
                        </Form>
                            <Modal
                                className="nimbly-wizard-warning"
                                open={this.state.showAlert}
                                footer={[
                                    <Button key="back" onClick={() => this.handleAletClose()} className="nimbly-wizard-warning-button">
                                        Back
                                    </Button>,
                                ]}
                                closable={true}
                            >
                                <Alert
                                    message="Warning"
                                    description="Please enter at least one searching value"
                                    type="warning"
                                    showIcon
                                />
                            </Modal>
                        </Card>
        );
    }

    async populateWizardData() {
        const response = await fetch('wizarddenc');
        const data = await response.json();
        console.log(data);
        const OptionListCrew = [];
        const OptionListAudito = [];
        var statusList = [], typeList = [], restorationList = [], cityList = [], regionList = [], attCompanyList = []
        var regionListobjEmpty = {}, statusListobjEmpty = {}, typeListobjEmpty = {}, restorationListobjEmpty = {}, cityListobjEmpty = {};
        //console.log(data);

        for (const key of data.crew_List) {
            if (key.crewName !== null) {
                var obj = {};
                obj["value"] = key.crewName;
                obj["label"] = key.crewName.toLowerCase().includes("kent\\") ? key.crewName.toLowerCase().replace('kent\\', '') : key.crewName;
                obj["key"] = key.crewName;
                OptionListCrew.push(obj);
            }
        }

        for (const key of data.auditor_List) {
            if (key.auditorName != null) {
                var objAuditor = {};
                objAuditor["value"] = key.auditorName;
                objAuditor["label"] = key.auditorName.toLowerCase().includes("kent\\") ? key.auditorName.toLowerCase().replace('kent\\', '') : key.auditorName;
                objAuditor["key"] = key.auditorName;
                OptionListAudito.push(objAuditor);
            }
        }

        for (var i = 0; i < data.polestatus_list.length; i++) {
            var value = data.polestatus_list[i].poleStatusName;
            if (value !== null) {
                statusListobjEmpty = {};
                statusListobjEmpty["value"] = value;
                statusListobjEmpty["label"] = value;
                statusListobjEmpty["key"] = value;
                statusList.push(statusListobjEmpty);
            }
        }

        for (var i = 0; i < data.pullPoleType_list.length; i++) {
            let value = data.pullPoleType_list[i].pullPoleTypeName;
            if (value !== null) {
                obj = {};
                obj["value"] = value;
                obj["label"] = value;
                obj["key"] = value;
                typeList.push(obj);
            }
        }
        for (var i = 0; i < data.restoration_list.length; i++) {
            let value = data.restoration_list[i].restorationName;
            if (value !== null) {
                obj = {};

                obj["value"] = value;
                obj["label"] = value;
                obj["key"] = value;
                restorationList.push(obj);
            }
        }
        for (var i = 0; i < data.area_list.length; i++) {
            let value = data.area_list[i].areaName;
            if (value !== null) {
                obj = {};
                obj["value"] = value;
                obj["label"] = value;
                obj["key"] = value;
                cityList.push(obj);
            }
        }

        for (var i = 0; i < data.state_list.length; i++) {
            let value = data.state_list[i].stateName;
            if (value !== null && value !== "") {
                obj = {};
                obj["value"] = value;
                obj["label"] = value;
                obj["key"] = value;
                regionList.push(obj);
            }
        }

        for (var i = 0; i < data.attcompany_list.length; i++) {
            let value = data.attcompany_list[i].companyName;
            if (value !== null && value !== "") {
                obj = {};
                obj["value"] = value;
                obj["label"] = value;
                obj["key"] = value;
                attCompanyList.push(obj);
            }
        }
        //this.setState({

        //    attCompanyOptions: attCompanyList
        //});

        this.setState({
            crewList: OptionListCrew,
            auditorList: OptionListAudito,
            statusOptions: statusList,
            typeOptions: typeList,
            restorationOptions: restorationList,
            cityOptions: cityList,
            regionOptions: regionList,
            attCompanyOptions: attCompanyList,
            isloading: false
        });
    }


    async populateResultData() {
        //console.log("pp");
        fetch("queryreportdenc", {
            headers: { "Content-Type": "application/json" },
            credentials: 'include',
            method: 'post',
            body: JSON.stringify({
                PoleNumber: this.state.PoleNumber == null ? "" : this.state.PoleNumber,
                PoleStatus: this.state.PoleStatus == null ? "" : this.state.PoleStatus,
                PoleType: this.state.PoleType == null ? "" : this.state.PoleType,
                NjunNumber: this.state.NjunNumber == null ? "" : this.state.NjunNumber,
                SpanNumber: this.state.SpanNumber == null ? "" : this.state.SpanNumber,
                Restoration: this.state.Restoration == null ? "" : this.state.Restoration,
                City: this.state.City == null ? "" : this.state.City,
                Ragion: this.state.Ragion == null ? "" : this.state.Ragion,
                crew: this.state.crew == null ? "" : this.state.crew,
                auditor: this.state.auditor == null ? "" : this.state.auditor,
                RemovalStartDate: this.state.RemovalStartDate == null ? "" : this.state.RemovalStartDate,
                RemovalCompletedDate: this.state.RemovalCompletedDate == null ? "" : this.state.RemovalCompletedDate,
                AuditStartDate: this.state.AuditStartDate == null ? "" : this.state.AuditStartDate,
                AuditCompletedDate: this.state.AuditCompletedDate == null ? "" : this.state.AuditCompletedDate,

            }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then(json => {
                this.setState({
                    showPoleInfoList: json.poleList,
                    isQuery: true,
                    isloading: false
                });
            }
            )
            .catch();


    }

    async populateAttcherResultData() {
        //console.log("pp");
        fetch("queryreportattacherdenc", {
            headers: { "Content-Type": "application/json" },
            credentials: 'include',
            method: 'post',
            body: JSON.stringify({
                attCompany: this.state.attCompany == null ? "" : this.state.attCompany,
                attStartDate: this.state.attStartDate == null ? "" : this.state.attStartDate,
                attEndDate: this.state.attEndDate == null ? "" : this.state.attEndDate,
            }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then(json => {
                //console.log("qr ", json.poleList);
                this.setState({
                    showPoleInfoList: json.poleList,
                    isQuery: true,
                    isloading: false
                });
            }
            )
            .catch();


    }
}

export default QueryWizard;
