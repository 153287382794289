import { DashboardOutlined, BookOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Layout, Menu, Col, Row, Spin, Select } from 'antd';
import logoblue from './img/logo-blue.png'; // with import
import delogo from './img/deLogo.png'; // with import
import React, { Component } from 'react';
import DEMWQueryWizard from './querywizard';
import DEMWDashboard from './dashborad';
import DENCQueryWizard from './denc/querywizard';
import DENCDashboard from './denc/dashborad';
import DEFLQueryWizard from './defl/querywizard';
import DEFLDashboard from './defl/dashborad';
import Profile from './profile';
import Usermanagement from './usermanagement';
import { Link } from "react-router-dom";
import { PageLoader } from "./page-loader";
const { SubMenu } = Menu;

const { Header, Content, Sider } = Layout;
const { Option } = Select;

class Home extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {
            loading: false,
            poleData: {
                poleList:[]
            },
            currentPage: 1,
            isloadingall: true
        };
    }

    componentDidMount() {
        console.log(this.props.userRole)
        this.setState({
            currentPage: this.props.showTab
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentProject !== this.props.currentProject) {
            //this.populatePoleData(this.props.currentProject);
            this.setState({
                isloadingall: true,
                poleData: {
                    poleList: []
                }
            });
        }
    }

    onClick = (e) => {
        this.setState({
            currentPage: e.key
        });
    };



    render() {
        return (
            this.state.loading ? <PageLoader /> : 
                <Layout>
                    <Header className="header nimbly-header-topnav">
                            <Row>
                                <Col span={20}>
                                <img alt="" className="nimbly-topnav-logo" src={logoblue} />
                                <Select className="nimbly-topnav-switchregin nimbly-chart-year-selector" defaultValue={this.props.currentProject} style={{ width: 220 }} onChange={this.props.handleProjectChange}>
                                    <Option value="Duke Midwest">Duke Midwest</Option>
                                    <Option value="Duke Carolina">Duke Carolina</Option>
                                    <Option value="Duke Florida">Duke Florida</Option>

                                </Select>
                                </Col>
                                <Col span={4}>
                                    <Profile />
                                </Col>
                            </Row>
                    </Header>
                    <Layout style={{height:"91vh"} }>
                        <Sider
                            width={200}
                            style={{
                                background: "white",
                            }}
                        >
                            <img alt="" className="nimbly-company-logo" src={delogo} />
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={this.props.userEmailDomain === "altafiber.com" ? "2" : "1"}
                                style={{
                                    height: '80%',
                                    borderRight: 0,
                                }}
                                onClick={(e) => this.onClick(e)}
                                selectedKeys={this.state.currentPage}
                                defaultOpenKeys={['sub4']}
                            >
                                <SubMenu
                                    key="sub4"
                                    title={
                                        <span>
                                            <BookOutlined className="nimbly-sub-sider-icon" />
                                            <span>{this.props.currentProject}</span>
                                        </span>
                                    }
                                >
                                    {this.props.userEmailDomain === "altafiber.com" ? null : <Menu.Item key="1" className="nimbly-sider-link">
                                        <DashboardOutlined className="nimbly-sider-icon" />
                                        <Link to="/dashboard">Dashboard</Link>
                                    </Menu.Item>}

                                    <Menu.Item key="2" className="nimbly-sider-link"
                                        //disabled={this.state.isloadingall}
                                    >
                                    
                                    {/*{this.state.isloadingall ? <Spin style={{*/}
                                    {/*    marginRight: '15px',*/}
                                    {/*    }} /> :*/}
                                            <BookOutlined className="nimbly-sider-icon" />
                                        {/*}*/}
                                    <Link to="/querywizard">Query Wizard
                                        
                                    </Link>
                                    </Menu.Item>

                                    <Menu.Item key="3" className="nimbly-sider-link"
                                        disabled={this.props.userRole === "Member"?true:false}
                                    >
                                        <UsergroupAddOutlined className="nimbly-sider-icon" />
                                        <Link to="/accessmanagement ">Access Management

                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                                {/*<SubMenu*/}
                                {/*    key="sub5"*/}
                                {/*    title={*/}
                                {/*        <span>*/}
                                {/*            <BookOutlined className="nimbly-sub-sider-icon" />*/}
                                {/*            <span>North Carolina</span>*/}
                                {/*        </span>*/}
                                {/*    }*/}
                                {/*>*/}
                                {/*    <Menu.Item key="1" className="nimbly-sider-link"><DashboardOutlined className="nimbly-sider-icon" /><Link to="/dashboard">Dashboard</Link></Menu.Item>*/}
                                {/*    <Menu.Item key="2" className="nimbly-sider-link" disabled={this.state.isloadingall}>*/}

                                {/*        {this.state.isloadingall ? <Spin style={{*/}
                                {/*            marginRight: '15px',*/}
                                {/*        }} /> : <BookOutlined className="nimbly-sider-icon" />}*/}
                                {/*        <Link to="/querywizard">Query Wizard*/}

                                {/*        </Link>*/}
                                {/*    </Menu.Item>*/}
                                {/*</SubMenu>*/}
                            </Menu>
                        </Sider>
                        <Layout
                            style={{
                                padding: '8px 8px 8px',
                                height: "100%"
                                
                            }}
                        >
                            <Content
                                className="nimbly-home-content"
                            >
                                {
                                    this.props.currentProject === "Duke Midwest" ?
                                        (this.state.currentPage === "1" ?
                                            <DEMWDashboard poleDataProps={this.state.poleData} />
                                            : (this.state.currentPage === "2" ? <DEMWQueryWizard poleDataProps={this.state.poleData} /> : <Usermanagement currentProject={this.props.currentProject} /> )) :
                                        this.props.currentProject === "Duke Carolina" ?
                                            (this.state.currentPage === "1" ?
                                                <DENCDashboard poleDataProps={this.state.poleData} />
                                                : (this.state.currentPage === "2" ? <DENCQueryWizard poleDataProps={this.state.poleData} /> : <Usermanagement currentProject={this.props.currentProject} />)) :
                                            this.props.currentProject === "Duke Florida" ?
                                                (this.state.currentPage === "1" ?
                                                    <DEFLDashboard poleDataProps={this.state.poleData} />
                                                    : (this.state.currentPage === "2" ? <DEFLQueryWizard poleDataProps={this.state.poleData} /> : <Usermanagement currentProject={this.props.currentProject} />)):
                                            null
                                }
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
        );
    }

    async populatePoleData(CurrentProject) {
        if (CurrentProject === "Duke Midwest") {
            const response = await fetch('report', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                method: 'post',
                body: JSON.stringify(
                    {
                    }
                )
            })
            const data = await response.json();
            this.setState({
                poleData: data,
                loading: false,
                isloadingall: false
            });
        }
        else if (CurrentProject === "Duke Carolina") {
            const response = await fetch('reportdenc', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                method: 'post',
                body: JSON.stringify(
                    {
                    }
                )
            })
            const data = await response.json();

            this.setState({
                poleData: data,
                loading: false,
                isloadingall: false
            });
        }
        else if (CurrentProject === "Duke Florida") {
            const response = await fetch('reportdefl', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                method: 'post',
                body: JSON.stringify(
                    {
                    }
                )
            })
            const data = await response.json();

            this.setState({
                poleData: data,
                loading: false,
                isloadingall: false
            });
        }
    }
}

export default Home;
