import React, { Component } from 'react';
//import './style/StyleSheet.css';
import { Col, Row } from 'antd';
import { Space, Table, Tag, Card, Pagination, Button, Modal } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import MapModal from './mapModal';




const columnsAttacher = [
    {
        title: 'Attacher Information',
        dataIndex: 'name',
        colSpan: 2,
        key: 'name',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        colSpan: 0,
    }
];
class ReportViewTable extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {
            isauthenticated: false,
            originalData: null,
            locationTableData: null,
            poleInfoTableData: null,
            totalReports: 0,
            imageList: [],
            imageCurrentShow: [],
            poleTableData: null,
            currentPolePage: 1,
            currentAttacher: null,
            currentAttacherPage: 1,
            isModalOpen: false,
            currentCoord: null
        };
    }

    componentDidMount() {
        this.populateReportData(this.props.showPoleInfoList);
    }

    onClick = (e) => {
        this.setState({
            currentPage: e.key
        });
    };

    handleModalOpen = (coords) => {
        this.setState({
            isModalOpen: true,
            currentCoord: coords
        });
    }

    handleModalClose = () => {
        this.setState({
            isModalOpen: false,
            currentCoord: null
        });
    }

    handleFormatKey = (keyName) => {
        if (keyName === "address") {
            return "Address"
        }
        else if (keyName === "coordinates") {
            return "Coordinates"
        }
        else if (keyName === "owner") {
            return "Owner"
        }
        else if (keyName === "states") {
            return "State"
        }
        else if (keyName === "area") {
            return "Area"
        }
        else if (keyName === "poleNumber") {
            return "Pole Number"
        }
        else if (keyName === "duke_Pole_Number") {
            return "Duke Pole Number"
        }
        else if (keyName === "poleStatus") {
            return "Pole Status"
        }
        else if (keyName === "pullPoleType") {
            return "Pull Pole Type"
        }
        else if (keyName === "accessible") {
            return "Accessible"
        }
        else if (keyName === "restoration") {
            return "Restoration"
        }
        else if (keyName === "restorationDate") {
            return "Restoration Date"
        }
        else if (keyName === "njuns") {
            return "NJUNS Number"
        }
        else if (keyName === "removalDate") {
            return "Removal Date"
        }
        else if (keyName === "poleRemover") {
            return "Pole Remover"
        }
        else if (keyName === "auditDate") {
            return "Audit Date"
        }
        else if (keyName === "auditor") {
            return "Auditor"
        }
        else if (keyName === "comments") {
            return "Notes"
        }
        else if (keyName === "attcompany") {
            return "Attachment Company"
        }
        else if (keyName === "transtype") {
            return "Transfer Type"
        }
        else if (keyName === "transqty") {
            return "Transfer QTY"
        }
        else if (keyName === "auditor_Notes") {
            return "Auditor Notes"
        }
        else if (keyName === "crew_Notes") {
            return "Crew Notes"
        }
        else if (keyName === "restored") {
            return "Restored"
        }
        else if (keyName === "transdate") {
            return "Transfer Date"
        }
        else if (keyName === "transcrew") {
            return "Transfer Crew"
        }
        else if (keyName === "audit_Date") {
            return "Audit Date"
        }
        else if (keyName === "attachment_Status") {
            return "Attachment Status"
        }
        else if (keyName === "spansNum") {
            return "SPANS Number"
        }
        else if (keyName === "cincinnatiBell_DeliveryArea") {
            return "Cincinnati Bell Delivery Area"
        }
        //else if (keyName === "invoiceDate") {
        //    return "Invoice Date"
        //}
        //else if (keyName === "district") {
        //    return "District"
        //}
        //else if (keyName === "globalID") {
        //    return "GlobalID"
        //}
        else {
            return keyName
        }
    }

    handleDataConversion = (currentPoleIndex) => {
        var currentPole = this.props.showPoleInfoList[currentPoleIndex - 1];
        const convertedData = [];
        var convertedDataAttacher = [];

        if (currentPole !== null && typeof currentPole !== 'undefined') {
            for (const [key, value] of Object.entries(currentPole).filter(([key, _]) => key !== "lat" && key !== "long")) {
                if (key !== "imagePath" && key !== "attachers" && key !== "globalID" && key !== "invoiceDate" && key !== "district") {
                    var obj = {};
                    obj["name"] = this.handleFormatKey(key);
                    obj["content"] = value;
                    convertedData.push(obj);
                }
            }
        }

        var Attachers = currentPole.attachers;
        if (Attachers != null && Attachers.length > 0) {
            for (var i in Attachers) {
                var attacher = Attachers[i];
                var attacherSingle = [];
                for (const [key, data] of Object.entries(attacher).filter(([key, _]) => key !== "lat" && key !== "long")) {
                    if (key !== "reL_GlobalID") {
                        obj = {};
                        obj["name"] = this.handleFormatKey(key);
                        obj["content"] = data;
                        obj["key"] = this.handleFormatKey(key);
                        attacherSingle.push(obj);
                    }
                }
                convertedDataAttacher.push(attacherSingle);
            }
        }
        this.setState({
            poleTableData: convertedData,
            attacherData: convertedDataAttacher,
            currentAttacher: convertedDataAttacher.length > 0 ? convertedDataAttacher[0] : null
        });
    };

    handlePageChange = (pageNumber) => {
        this.handleDataConversion(pageNumber);
        this.setState({
            currentPole: pageNumber,
            currentPolePage: pageNumber
        })
    }

    handleAttacherPageChange = (pageNumber) => {
        this.setState({
            currentAttacher: this.state.attacherData == null ? null : this.state.attacherData[pageNumber - 1],
            currentAttacherPage: pageNumber
        })
    }
    render() {

        const columnsPole = [
            {
                title: 'Pole Information',
                dataIndex: 'name',
                colSpan: 2,
                key: 'name',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
                colSpan: 0,
                render: (_, record) => (
                    record.name === "Coordinates" ?
                        <Space>
                            <a onClick={() => this.handleModalOpen(record.content)}>{record.content}</a>
                        </Space>
                        : record.content
                )
            }
        ];
        return (
            <div>
                {this.props.dataSource === "wizard" ?
                    <Row>
                        <Button className="nimbly-report-goback-button" onClick={() => this.props.onReportGoback("Table")} icon={<RollbackOutlined />}>Back</Button>
                    </Row> : null}
                {
                    this.props.dataSource === "wizard" ? 
                        <Row className="nimbly-table-view-contrainer">
                            <Card style={{ width: "100%" }}>
                                <Row>
                                    <Col span={23} style={{ marginRight: "10px" }}>
                                        <Table className="nimbly-table-view-table" columns={columnsPole} dataSource={this.state.poleTableData == null ? null : this.state.poleTableData} pagination={false} />
                                    </Col>
                                    {/*<Col span={10}>*/}
                                    {/*    <Table className="nimbly-report-information" columns={columnsAttacher} dataSource={this.state.currentAttacher == null ? null : this.state.currentAttacher} pagination={false} />*/}
                                    {/*    {this.state.attacherData == null || this.state.attacherData.length === 0 ? null :*/}
                                    {/*        <div className="nimbly-report-pagination-wrapper">*/}
                                    {/*            <Pagination*/}
                                    {/*                total={this.state.attacherData.length}*/}
                                    {/*                onChange={(pageNumber) => this.handleAttacherPageChange(pageNumber)}*/}
                                    {/*                size="small"*/}
                                    {/*                pageSize={1}*/}
                                    {/*                current={this.state.currentAttacherPage}*/}
                                    {/*                showSizeChanger={false}*/}
                                    {/*            />*/}
                                    {/*        </div>}*/}
                                    {/*</Col>*/}
                                </Row>
                            </Card>

                        </Row> : 
                        <Row>
                            <Card>
                                <Table className="nimbly-table-view-table-map" columns={columnsPole} dataSource={this.state.poleTableData == null ? null : this.state.poleTableData} pagination={false} />
                            </Card>
                        </Row>
                }

                <div className="nimbly-report-pagination-wrapper">
                    <Pagination
                        total={this.state.totalReports}
                        onChange={(pageNumber) => this.handlePageChange(pageNumber)}
                        size="large"
                        pageSize={1}
                        showSizeChanger={false}
                        current={this.state.currentPolePage}
                    />
                </div>

                <Modal
                    open={this.state.isModalOpen}
                    footer={[
                        <Button key="back" onClick={() => this.handleModalClose()}>
                            Close Map
                        </Button>,
                    ]}
                    closable={false}

                >
                    <MapModal coords={this.state.currentCoord} />
                </Modal>
            </div>
        );
    }

    async populateReportData(formData) {
        this.setState({
            originalData: formData,
            loading: false,
            totalReports: formData.length,
        });
        this.handleDataConversion(1);
    }
}

export default ReportViewTable;
