import {Modal, Button, Select, Card } from 'antd';
import React, { Component } from 'react';
import Home from './home'
import image from "./img/landingpage.png";
import { withRouter } from "react-router-dom";
import { PageLoader } from "./page-loader";


const { Option } = Select;

class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {
            loading: true,
            poleData: {
                poleList: []
            },
            currentPage: 1,
            isloadingall: true,
            visible: true,
            unselect: true,
            currentProject: "Duke Midwest",
            projectAssigned: null,
            role: null
        };
    }

    componentDidMount() {
        this.populateProjectAssigned();
        this.setState({
            currentPage: this.props.showTab
        });
    }

    onClick = (e) => {
        this.setState({
            currentPage: e.key
        });
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            unselect: false
        });


    };

    handleChange = (value) => {
        if (value !== "Select a Region") {
            this.setState({
                currentProject: value,
            });
        }
}

    render() {
        return (
            this.state.loading ? <PageLoader /> :
            !this.state.unselect ?
                    /*(this.state.currentProject === "denc" ? <Home /> : this.state.currentProject === "demw" ? <HomeDEMW showTab="1" /> : null)*/
                    <Home showTab={this.props.userEmailDomain === "altafiber.com" ? "2" : "1"} currentProject={this.state.currentProject} handleProjectChange={this.handleChange} userEmailDomain={this.props.userEmailDomain}
                        //userRole={this.state.currentProject == "Duke Midwest" ? this.state.projectAssigned.find(item => item.project === "DEMW").role : this.state.projectAssigned.find(item => item.project === "DENC").role}
                        userRole={this.state.role}
                    />
                :
                <div>
                    {/*<Modal*/}
                    {/*    title="Please select a project"*/}
                    {/*    visible={this.state.visible}*/}
                    {/*    onOk={this.handleOk}*/}
                    {/*    onCancel={this.handleCancel}*/}
                    {/*    footer={[*/}
                    {/*        <Button key="back" onClick={this.handleCancel}>*/}
                    {/*            Open*/}
                    {/*        </Button>,*/}
                    {/*    ]}*/}
                    {/*    closable={false}*/}
                    {/*    maskClosable={false}*/}
                    {/*>*/}
                    {/*    <Select defaultValue="Duke Midwest" style={{ width: 220 }} onChange={this.handleChange}>*/}
                    {/*        <Option value="Duke Midwest">Duke Midwest</Option>*/}
                    {/*        <Option value="Duke Caroline">Duke Caroline</Option>*/}
                    {/*    </Select>*/}
                    {/*</Modal>*/}
                    <div className="nimbly-landingpage-container">
                        <img alt="" className="nimbly-landingpage-image" src={image} />

                        <Card className="nimbly-landingpage-message-container">
                            <h2 className="nimbly-noaccess-message-text">Welcome, please select your region.</h2>
                            <Select className="nimbly-chart-year-selector" defaultValue="Select a Region" style={{ width: 220 }} onChange={this.handleChange}>
                                <Option
                                    value="Duke Midwest"
                                    //disabled={this.state.projectAssigned == null ? null : ((this.state.projectAssigned.find(item => item.project === "DEMW") || this.state.role === "Administrator") ? false : true)}
                                >
                                    Duke Midwest
                                </Option>
                                <Option
                                    value="Duke Carolina"
                                        //disabled={this.state.projectAssigned == null ? null : ((this.state.projectAssigned.find(item => item.project === "DENC") || this.state.role === "Administrator") ? false : true)}
                                >
                                    Duke Carolina
                                    </Option>
                                    <Option
                                        value="Duke Florida"
                                    //disabled={this.state.projectAssigned == null ? null : ((this.state.projectAssigned.find(item => item.project === "DENC") || this.state.role === "Administrator") ? false : true)}
                                    >
                                        Duke Florida
                                    </Option>
                            </Select>
                            <br />
                            <Button className="nimbly-landingpage-button" key="back" onClick={this.handleCancel}>
                                        Confirm
                                    </Button>
                        </Card>
                    </div>
                </div>
        );
    }

    async populateProjectAssigned() {
        const response = await fetch('projectAssignees', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            method: 'post',
            body: JSON.stringify(
                {
                    userid: this.props.userAuth0ID
                }
            )
        })
        const data = await response.json();
        console.log(data);
        this.setState({
            projectAssigned: data,
            role: data.find(item => item.role === "Administrator") ? "Administrator" :"Member",
            loading: false,
            isloadingall: false
        });
    }
}

export default LandingPage;
