import React, { Component } from 'react';
import Home from './home';
import Login from './login';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import QueryWizard from './querywizard';
import Dashboard from './dashborad';
import ReportView from './reportview'
import TableView from './reportviewtable'
import InvoiceReport from './invoiceReport'
import { AuthenticationGuard } from './protected-route';
import { PageLoader } from "./page-loader";
import { useAuth0 } from "@auth0/auth0-react";
import CheckAuthentication from "./isAuthenticated"
import CheckReportAuthentication from "./isReportAuthenticated"
import { NoAccess } from './noaccess';

export const App = () => {

    const { isLoading } = useAuth0();

    if (isLoading) {
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        );
    }

    return (
        <Routes basename={process.env.PUBLIC_URL}>
            {/*<Route path="/" element={<CheckAuthentication showTab="1" />}/>*/}
            {/*<Route path="/" element={<CheckAuthentication/>} />*/}
            <Route exact path="/login" element={<Login />} />
            <Route path="/dashboard" element={<CheckAuthentication showTab = "1"/>} />
            <Route path="/querywizard" element={<CheckAuthentication showTab="2" />} />

            {/*<Route exact path="/invoicereport/:we/:who/:globalid/:servicearea" element={<InvoiceReport />} />*/}
            {/*<Route exact path="/invoicereport/:we/:who/:globalid/:servicearea" element={<CheckReportAuthentication />} />*/}

            <Route exact path="/invoicereport/:report" element={<CheckReportAuthentication />} />
            <Route exact path="/invoicereport/*" element={<CheckReportAuthentication />} />

            {/*<Route path="*" element={<Navigate to="/" replace />} />*/}
            <Route path="*" element={<CheckAuthentication />} />

            {/*<Route path="/" element={<Navigate to="/dashboard" replace />} />*/}
        </Routes>

        //<Routes>
        //    <Route exact path="/login" element={<Login />} />
        //    <Route path="/" element={<AuthenticationGuard component={Home} showTab="1" />} />
        //    <Route path="/dashboard" element={<AuthenticationGuard component={Home} showTab="1"/>} />
        //    <Route path="/querywizard" element={<AuthenticationGuard component={Home} showTab="2" />} />
        //    <Route path="*" element={<Navigate to="/" replace />} />
        //</Routes>
    );
};
