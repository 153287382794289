import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Breadcrumb, Layout, Menu, theme, Col, Row, Popover } from 'antd';
import LogoutButton from './auth0logout';

const Profile = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    const nameFormat = (name) => {
        var nameLong = name.split('@')[0];
        var nameList = nameLong.split('.');
        var FirstName = nameList[0].charAt(0).toUpperCase() + nameList[0].substring(1)
        var LastName = nameList[1].charAt(0).toUpperCase() + nameList[1].substring(1)
        var displayName = FirstName + " " + LastName;
        return displayName;
    }


    return (
        isAuthenticated && (
            <div>
                {/*<img src={user.picture} alt={user.name} />*/}
                {/*<h2>{user.name}</h2>*/}
                {/*<p>{user.email}</p>*/}
                <Row className="nimbly-header-user-profile">
                    <Col span={19}>
                        <p className="nimbly-header-profile-name">{user.name}</p>
                        <p className="nimbly-header-profile-email">{user.email}</p>
                    </Col>
                    <Col span={5}>
                        <Popover content={<LogoutButton />}>
                            <img src={user.picture} alt={user.name} className="nimbly-profile-picture" />
                        </Popover>
                    </Col>
                </Row>
            </div>
        )
    );
};

export default Profile;